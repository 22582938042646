import React, { useEffect, useState, useRef} from 'react';
import { Routes, Route } from 'react-router-dom';

import DashboardIcon from '@mui/icons-material/Dashboard';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone';
import UploadIcon from '@mui/icons-material/Upload';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import UploadTwoToneIcon from '@mui/icons-material/UploadTwoTone';
import AssuredWorkloadTwoToneIcon from '@mui/icons-material/AssuredWorkloadTwoTone';
import CalculateTwoToneIcon from '@mui/icons-material/CalculateTwoTone';
import HandshakeTwoToneIcon from '@mui/icons-material/HandshakeTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import FindInPageTwoToneIcon from '@mui/icons-material/FindInPageTwoTone';
import TravelExploreTwoToneIcon from '@mui/icons-material/TravelExploreTwoTone';

import Rerouter from '../Public/Rerouter.js';
import Dashboard from "./Dashboard.js";
import Reporting from "./Reporting.js";
import Upload from './Upload.js';
import ResearchHub from './ResearchHub.js';
import AccountCenter from './AccountCenter.js';
import MessageBoard from '../Components/MessageBoard/MessageBoard.js';
import ProgressionBar from "./ProgressionBar.js";
import Tab from '../Components/Tab.js';
import { CircularProgress } from '@mui/material';

function LifeTracPortal(props){
    const session = props?.session;
    const policyHandler = session?.individual?.policyHandler;
    const envFunctions = session?.env?.functions;
    const viewport = session?.env?.viewport;
    const viewType = viewport?.viewType;
    const viewWidth = viewport?.width;
    const currentPage = session?.user?.data?.currentActivity;
    const userFunctions = session?.user?.functions;
    const [transcript, setTranscript] = useState();
    const iframeRef = useRef(null);
    const reportingAlt = viewType === "mobile" && currentPage === "reporting";

    useEffect(() => {
      window.addEventListener('message', receiveMessage, false);
      return () => {
        window.removeEventListener('message', receiveMessage, false);
      };
    }, []);
  
    const receiveMessage = (event) => {
      if (event.data && event.data.type === 'iframeHeight') {
        const height = event.data.height;
        if (iframeRef.current) {
          iframeRef.current.style.height = height + 'px';
        }
      }
    };

    function contentClass() {
        return '';
        if (currentPage === "dashboard") {
          return viewType !== "mobile" ? "f gR2 mA" : "f gR3";
        } else {
          return viewType !== "mobile" ? "f" : currentPage === "details" || currentPage === "reporting" ? "f" : "f gR2";
        }
    }

    function pageFormat(){
        if(viewType === "mobile"){
            if(currentPage === "details" || currentPage === "reporting"){
                return "gR2";
            }

            return "gR3"
        }else{
            if(currentPage === "dashboard"){
                return "gR2";
            }else{
                return '';
            }
        }
    }

    const tabSetup = () => {
        if(session?.user?.data?.views?.includes("Upload") && session?.user?.data?.views?.includes("Research Hub") && session?.user?.data?.views?.includes("Account Center") ){
            return 5;
            }
        else if(session?.user?.data?.views?.includes("Upload") && session?.user?.data?.views?.includes("Research Hub")){
          return 4;
        }
        else if(session?.user?.data?.views?.includes("Upload") || session?.user?.data?.views?.includes("Research Hub")){
          return 3;
        }
        else{
          return 2;
        } 
      }

    useEffect(() => {
        if(currentPage !== "upload" && transcript){
            setTranscript();
        }
    }, [currentPage]);

    return (
        <div className="lifeTracPortal fMP g oH bR gR2">
            {viewType === "mobile" &&
                <div className={`header f g cC fR fC ${tabSetup() === 5 ? 'tabs5' : tabSetup() === 4 ? 'tabs4' : tabSetup() === 3 ? "tabs3" : "tabs2" }`}>
                    <div className="f cC g">
                        <Tab
                            isActive={currentPage === "dashboard"}
                            onClick={() => userFunctions?.updateUserData(["currentActivity"], ["dashboard"])}
                            label="Dashboard"
                            icon={<DashboardTwoToneIcon />}
                            alertType={"Status"}
                            viewType={viewType}
                            session={session}
                            currentPolicy={props?.currentPolicy}
                        />
                        <Tab
                            isActive={currentPage === "reporting"}
                            onClick={() => props?.session?.user?.functions?.updateUserData(["currentActivity"], ["reporting"])}
                            label="Reporting"
                            icon={<AssessmentTwoToneIcon />}
                            viewType={viewType}
                            session={session}
                        />
                        { session?.user?.data?.views?.includes("Upload") &&
                            <Tab
                                isActive={currentPage === "upload"}
                                onClick={() => props?.session?.user?.functions?.updateUserData(["currentActivity"], ["upload"])}
                                label="Upload"
                                icon={<UploadTwoToneIcon />}
                                viewType={viewType}
                                alertType={"Message"}
                                session={session}
                            />
                        }
                        { session?.user?.data?.views?.includes("Research Hub") &&
                            <Tab
                                isActive={currentPage === "researchHub"}
                                onClick={() => props?.session?.user?.functions?.updateUserData(["currentActivity"], ["researchHub"])}
                                label="Research Hub"
                                icon={<TravelExploreTwoToneIcon/>}
                                viewType={viewType}
                                session={session}
                            />
                        }
                    </div>
                </div>
            }
            {!session ?
                <div className="loadingScreen f cC">
                    <div className="loader cC g">
                        <div className="cC g fR">
                            <div className="icon cC">
                                <CalculateTwoToneIcon/>
                            </div>
                            <div className="icon cC">
                                <HandshakeTwoToneIcon/>
                            </div>
                            <div className="icon cC">
                                <AssuredWorkloadTwoToneIcon/>
                            </div>
                        </div>
                        <div className="cC">
                            We're gathering your account information.
                        </div>
                    </div>
                </div>
            :
                <>
                    <div className={`activity f g fC oH bR ${session?.user?.data?.currentActivity}${viewType === "mobile" ? " gR2" : ''}`}>
                        <Routes>
                            <Route
                                path="/dashboard"
                                element={
                                    <Dashboard
                                        session={session}
                                        currentPolicy={props?.currentPolicy}
                                        envFunctions={envFunctions}
                                        policyHandler={policyHandler}
                                        viewType={viewType}
                                    />
                                }
                            />
                            <Route
                                path="/reporting"
                                element={
                                    <Reporting
                                        session={session}
                                        currentPolicy={props?.currentPolicy}
                                        envFunctions={envFunctions}
                                        policyHandler={policyHandler}
                                        viewType={viewType}
                                        viewport={viewport}
                                    />
                                }
                            />
                            <Route
                                path="/upload"
                                element={
                                    <Upload
                                        session={session}
                                        currentPolicy={props?.currentPolicy}
                                        envFunctions={envFunctions}
                                        policyHandler={policyHandler}
                                        viewType={viewType}
                                    />  
                                }
                            />
                            <Route
                                path="/researchHub"
                                element={
                                    <ResearchHub
                                        session={session}
                                        currentPolicy={props?.currentPolicy}
                                        envFunctions={envFunctions}
                                        policyHandler={policyHandler}
                                        viewType={viewType}
                                    />  
                                }
                            />
                            <Route
                                path="/accountCenter"
                                element={
                                    <AccountCenter
                                        session={session}
                                        currentPolicy={props?.currentPolicy}
                                        envFunctions={envFunctions}
                                        policyHandler={policyHandler}
                                        viewType={viewType}
                                    />  
                                }
                            />
                            <Route
                                path="/*"
                                element={
                                    <Rerouter/>
                                }
                            />
                        </Routes>
                        {/* <div className={`f oA${!reportingAlt ? " dP" : ''} ` + pageFormat()}>
                            <div className={`content oH${!reportingAlt ? " bR" : ''} g ${contentClass()}`}>
                                <div className={`f tO pR${reportingAlt ? " oH" : ''}`}>
                                    <Routes>
                                        <Route
                                            path="/dashboard"
                                            element={
                                                <Dashboard
                                                    session={session}
                                                    currentPolicy={props?.currentPolicy}
                                                    envFunctions={envFunctions}
                                                    policyHandler={policyHandler}
                                                    viewType={viewType}
                                                />
                                            }
                                        />
                                        <Route
                                            path="/reporting"
                                            element={
                                                session?.shareLink?.data?.[props?.currentPolicy?.recordID]?.shareLink ?
                                                    <iframe
                                                        ref={iframeRef}
                                                        className="f"
                                                        src={session?.shareLink?.data?.[props?.currentPolicy?.recordID]?.shareLink}
                                                        allowFullScreen
                                                    />
                                                :
                                                    <div className="empty f cC dP">
                                                        <div>
                                                            {props?.initialize?.loadHandler?.shareLinkData === "pending" ?
                                                                <>
                                                                    <CircularProgress/>
                                                                    <div>
                                                                        Loading your Documents Portal.
                                                                    </div>
                                                                </>
                                                            :
                                                                <>
                                                                    <ErrorTwoToneIcon/>
                                                                    <div>
                                                                        If you're seeing this, try refereshing or reaching out to us directly so that we can get you connected to your Documents Portal.
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                            }
                                        />
                                        <Route
                                            path="/upload"
                                            element={
                                                <MessageBoard
                                                    session={session}
                                                    currentPolicy={props?.currentPolicy}
                                                    viewType={viewType}
                                                    transcript={transcript}
                                                    setTranscript={setTranscript}
                                                />
                                            }
                                        />
                                    </Routes>
                                </div>
                            </div>
                            {viewType !== "full" &&
                                <Routes>
                                    <Route
                                        path="/upload"
                                        element={
                                            <MessageBoard
                                                session={session}
                                                currentPolicy={props?.currentPolicy}
                                                viewType={viewType}
                                                inputOnly={true}
                                                transcript={transcript}
                                                setTranscript={setTranscript}
                                            />
                                        }
                                    />
                                    <Route path="/details" element={null}/>
                                    <Route path="/dashboard" element={null}/>
                                    <Route path="/reporting" element={null}/>
                                </Routes>

                            }
                        </div> */}
                    </div>
                </>
            }
        </div>
    )
};

export default LifeTracPortal;