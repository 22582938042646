import React, { useEffect, useState } from 'react';

function ResearchVars(props){

    const [inputData, setInputData] = useState({
        first_name: '',
        middle_name: '',
        last_name: '',
        ssn: '',
        dob: '',
        state: '',
    })
    
    const [data, setData] = useState({
        firstName : '',
        landingPage: true,
        inputData,
    });    

    function loadResearchVars(notificationData){
        setData(notificationData);
    }

    const functions = {
        setInputData
    }

    const researchVars = {
        data,
        functions
    }

    return researchVars;
};

export default ResearchVars;