import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import UploadIcon from '@mui/icons-material/Upload';

import DownloadIcon from '@mui/icons-material/Download';

import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import StatusIcon from '../../Components/StatusIcon';

import { blue, purple } from '@mui/material/colors';

function ActionBar(props){
    const session = props?.session;
    const envFunctions = session?.env?.functions;
    const userFunctions = session?.user?.functions;
    const individualFunctions = session?.individual?.functions;
    const individualData = session?.individual?.data[session?.handler?.data?.currentAccountID];
    const policyHandler = session?.individual?.policyHandler;
    const policyList = session?.individual?.data[session?.handler?.data?.currentAccountID]?.policy;
    const sessionUser = props?.session?.user?.data;
    const viewType = session?.env?.viewport?.viewType;

    const currentList = session?.edit?.data?.importedList?.list;
    const uploadFunctions = session?.upload?.functions;

    const navigate = useNavigate();

    const actions = {
        transition : undefined,
        submission : undefined
    }

    if(session?.env?.overlay === "individualProfile"){
        if(individualData?.selectedProfile?.active?.upload){
            const singleUploadData = {individual: individualData?.selectedProfile?.active?.profile}
            const modifiedObj = {
                ...singleUploadData.individual, // Copy the original object
                dob: session?.upload?.functions?.convertToISODate(singleUploadData.individual.dob), // Update the value of the specific field
            };
            const singleUploaUpdatedData = {individual: modifiedObj, accountID: session?.handler?.data?.currentAccountID,             
                uploadData: {
                    city: 0,
                    dob: 0,
                    file_name: (singleUploadData.individual?.first_name || '') + (singleUploadData.individual?.last_name || ''),
                    first_name: 0,
                    last_name: 0,
                    ssn: 0,
                    state: 0,
                    total_participants: 1,
                    zip_code: 0
                }
            };
            actions.submission = {
                type : "save",
                prompt : "Save",
                function : () => {
                    session?.upload?.setData(prevData => ({
                        ...prevData,
                        singleUploadSuccess: true
                    }));
                    props?.setter(false);
                    individualFunctions?.uploadOne(session, singleUploaUpdatedData);
                },
                icon : <UploadIcon/>
            }
        }
        else{
            // actions.submission = {
            //     type : "save",
            //     prompt : "Save",
            //     function : () => {
            //         individualFunctions?.uploadOne(session, individualData?.selectedProfile?.active?.profile);
            //     },
            //     icon : <UploadIcon/>
            // }
            actions.submission = undefined
        }
    }
    else if(session?.env?.overlay === "uploadSuccess"){
        if(currentList){
            actions.transition = {
                type : "download",
                prompt : viewType !== "full" ? "New File" : "Download Uploaded File",
                function : () => {
                    uploadFunctions?.exportToExcel(currentList, "UploadFile", session?.individual?.data[session?.handler?.data?.currentAccountID]?.individualAttributesData);
                },
                icon : <DownloadIcon/>
            }
            actions.submission = {
                type : "navigate",
                prompt : viewType !== "full" ? "Dashboard" : "Return to Dashboard" ,
                function : () => {    
                    props?.setter(false);
                    navigate("/dashboard");
                },
                icon : <ExitToAppIcon/>
            }
        }
        else{
            // actions.submission = {
            //     type : "save",
            //     prompt : "Save",
            //     function : () => {
            //         individualFunctions?.uploadOne(session, individualData?.selectedProfile?.active?.profile);
            //     },
            //     icon : <UploadIcon/>
            // }
            actions.submission = undefined
        }
    }
    if(session?.env?.nestedOverlay?.name === "disableIndividual" && props?.overlayProps){
        actions.transition = {
            type : "navigate",
            prompt : "Cancel" ,
            function : () => {    
                props?.setter(false);  
            },
            // icon : <DownloadIcon/>
        }
        actions.submission = {
            type : "delete",
            prompt : "Confirm",
            function : () => {
                props?.setter(false); 
                session?.reporting?.functions?.disableIndividual(session, session?.handler?.data?.currentAccountID, props?.overlayProps?.recordID);
            },
            // icon : <ExitToAppIcon/>
        }
    }
    else if(session?.env?.nestedOverlay?.name === "deleteReport"){
        actions.transition = {
            type : "navigate",
            prompt : "Cancel" ,
            function : () => {    
                props?.setter(false);  
            },
            // icon : <DownloadIcon/>
        }
        actions.submission = {
            type : "delete",
            prompt : "Confirm",
            function : () => {
                props?.overlayProps?.deleteReport(props?.overlayProps?.recordID);
                props?.setter(false);  
            },
            // icon : <ExitToAppIcon/>
        }
    }
    else if(session?.env?.nestedOverlay?.name === "removeUser"){
        actions.transition = {
            type : "navigate",
            prompt : "Cancel" ,
            function : () => {    
                props?.setter(false);  
            },
            // icon : <DownloadIcon/>
        }
        actions.submission = {
            type : "delete",
            prompt : "Confirm",
            function : () => {
                props?.overlayProps?.deleteUser(props?.overlayProps?.selectedUser?.recordID);
                props?.overlayProps?.setRemovingUser(false);
                props?.overlayProps?.setRemoveClicked(true);
                props?.overlayProps?.setSelectedItemIndex(null);
                props?.overlayProps?.setCurrentlyEditing(false);
                props?.setter(false); 
            },
            // icon : <ExitToAppIcon/>
        }
    }
    else if(session?.env?.nestedOverlay?.name === "generateKey"){
        actions.transition = {
            type : "navigate",
            prompt : "Cancel" ,
            function : () => {    
                props?.setter(false);  
            },
            // icon : <DownloadIcon/>
        }
        actions.submission = {
            type : "delete",
            prompt : "Confirm",
            function : () => {
                props?.overlayProps?.generateApiKey();
                props?.overlayProps?.setCopyClicked(false);
                props?.setter(false); 
            },
            // icon : <ExitToAppIcon/>
        }
    }
    return (
        <>
            <div className="actionBar f g fR dP">
                {actions?.transition &&
                    <div className="transition gC1 f cL">
                        <div
                            className={`btn default f cC bR p ${(actions?.transition?.icon || actions?.submission?.icon) ? 'g' : ''}`}
                            onClick={()=>{
                                actions?.transition?.function();
                            }}
                        >
                            {actions?.transition?.prompt}
                            {actions?.transition?.icon}
                        </div>
                    </div>
                }
                {actions?.submission &&
                    <div className="submission gC3 f cR">
                        <div
                            className={`btn default f cC bR p ${(actions?.transition?.icon || actions?.submission?.icon) ? 'g' : ''}`}
                            onClick={(e)=>{
                                actions?.submission?.function();
                            }}
                        >
                            {actions?.submission?.prompt}
                            {actions?.submission?.icon}
                        </div>
                    </div>
                }
            </div>
        </>
    )
};

export default ActionBar;