import EnvVars from './EnvVars.js';
import SessionUser from './SessionUser.js';
import SessionAccount from './SessionAccount.js';
import SessionIndividual from './SessionIndividual.js';
import ShareLink from './ShareLink.js';
import Notifications from './Notifications.js';
import ReportingVars from './ReportingVars.js';
import DashboardVars from './DashboardVars.js';
import UploadVars from './UploadVars.js';
import EditSheetVars from './EditSheetVars.js';
import SessionHandler from './SessionHandler.js';
import ResearchVars from './ResearchVars.js';

function SessionVars(props){
    const user = SessionUser();
    const account = SessionAccount();
    const individual= SessionIndividual();
    const shareLink = ShareLink();
    const notifications = Notifications();
    const reporting= ReportingVars();
    const dashboard = DashboardVars();
    const env = EnvVars(user);
    const upload = UploadVars();
    const edit = EditSheetVars();
    const handler = SessionHandler(account);
    const research = ResearchVars();

    // const userModule = SessionUser();
    // const accountModule = SessionAccount();
    // const individualModule = SessionIndividual();
    // const shareLinkModule = ShareLink();
    // const notificationsModule = Notifications();
    // const reportingModule = ReportingVars();
    // const dashboardModule = DashboardVars();
    // const envModule = EnvVars(user);
    // const uploadModule = UploadVars();
    // const editModule = EditSheetVars();
    // const handlerModule = SessionHandler(account);
    // const researchModule = ResearchHub();

    // const modules = React.useMemo(() => ({
    //     env: envModule,
    //     user: userModule,
    //     account: accountModule,
    //     individual: individualModule,
    //     reporting: reportingModule,
    //     dashboard: dashboardModule,
    //     upload: uploadModule,
    //     edit: editModule,
    //     handler: handlerModule,
    //     research: researchModule,
    //     shareLink: shareLinkModule,
    //     notifications: notificationsModule,

    // }), [
    //     envModule,
    //     userModule,
    //     accountModule,
    //     individualModule,
    //     reportingModule,
    //     dashboardModule,
    //     uploadModule,
    //     editModule,
    //     handlerModule,
    //     researchModule,
    //     shareLinkModule,
    //     notificationsModule,
    // ]);

    const sessionVars = {
        env,
        user,
        account,
        individual,
        reporting,
        dashboard,
        shareLink,
        notifications,
        upload,
        edit,
        handler,
        research,
    }

    // function set(base, paths, values, msg) {
    //     const module = modules?.[base];
    //     if (module?.setData) {
    //         module.setData(prev => {
    //             if (paths === null) {
    //                 return values; // Replace entire module data
    //             }
        
    //             let newState = { ...prev };
    //             if (Array.isArray(paths)) {
    //                 paths.forEach((path, index) => {
    //                     newState = updateModule(newState, path, values[index], msg); // Use memoized version
    //                 });
    //             } else {
    //                 newState = updateModule(newState, paths, values, msg);
    //             }
    //             return newState;
    //         });
    //     } else {
    //         console.warn(`Unknown base: ${base}`);
    //     }
    // }

    // function updateModule(prev, path, value, msg) {
    //     const parts = path?.split('.').map(part => part.replace(/\[([^\]]+)\]/g, '.$1')).flatMap(part => part.split('.'));
    //     const newState = { ...prev }; // Shallow copy the top-level object
    //     let current = newState;
    
    //     for (let i = 0; i < parts.length - 1; i++) {
    //         const part = parts[i];
    //         if (typeof current[part] !== 'object' || current[part] === null) {
    //             current[part] = /^\d+$/.test(parts[i + 1]) ? [] : {};
    //         } else {
    //             current[part] = Array.isArray(current[part]) ? [...current[part]] : { ...current[part] };
    //         }
    //         current = current[part];
    //     }
    
    //     const lastPart = parts[parts.length - 1];
    //     if (!_.isEqualWith(current[lastPart], value, customCompare)) {
    //         // console.log('Difference detected:', path, current[lastPart], value, msg);
    //         current[lastPart] = _.cloneDeep(value); // Deep clone the value to avoid reference issues
    //         return newState;
    //     } else {
    //         // console.log("No difference found", path, msg);
    //         return prev; // Return previous state if no changes
    //     }
    // }

    // function customCompare(objValue, othValue) {
    //     if (React.isValidElement(objValue) && React.isValidElement(othValue)) {
    //         return compareReactElements(objValue, othValue);
    //     }
    //     return undefined;
    // }

    // function compareReactElements(elem1, elem2) {
    //     const significantProps1 = filterProps(elem1.props);
    //     const significantProps2 = filterProps(elem2.props);
    //     return elem1.key === elem2.key && deepEqual(significantProps1, significantProps2);
    // }

    // function filterProps(props) {
    //     const { _owner, _store, _self, _source, ...significantProps } = props;
    //     return significantProps;
    // }

    // function deepEqual(obj1, obj2) {
    //     if (Array.isArray(obj1) && Array.isArray(obj2)) {
    //         if (obj1.length !== obj2.length) return false;
    //         for (let i = 0; i < obj1.length; i++) {
    //             if (!_.isEqualWith(obj1[i], obj2[i], customCompare)) return false;
    //         }
    //         return true;
    //     }
    //     return _.isEqualWith(obj1, obj2, customCompare);
    // }

    // return {
    //     ...modules,
    //     set,
    //     useListen: (callback, value, deep = false, call) => useDeepEffect(callback, value, deep, call, modules?.env?.isTyping),
    //     useComplexListen: (effects, deep = false, call) => useComplexEffect (effects, deep, call),
    // };

    return sessionVars;
};

export default SessionVars;