import React, { useState, useEffect, useRef } from 'react';

import jsPDF from "jspdf";
import ABLLogo from '../Images/ablTechLogoNoTagline.png';

import InputField from "../Components/InputField.js";
import ViewBarBtn from '../Components/ViewBarBtn.js';
import Dropdown from '../Components/Dropdown';
import MapComponent from '../Components/MapComponent.js';
import IosShareIcon from '@mui/icons-material/IosShare';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import TravelExploreTwoToneIcon from '@mui/icons-material/TravelExploreTwoTone';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import VerifiedIcon from '@mui/icons-material/Verified';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import PersonIcon from '@mui/icons-material/Person';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import CircularProgress from '@mui/material/CircularProgress';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PushPinIcon from '@mui/icons-material/PushPin';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InfoIcon from '@mui/icons-material/Info';
import LinkIcon from '@mui/icons-material/Link';
import ArticleIcon from '@mui/icons-material/Article';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import LanguageIcon from '@mui/icons-material/Language';
import HomeIcon from '@mui/icons-material/Home';
import SignpostIcon from '@mui/icons-material/Signpost';
import EscalatorWarningOutlinedIcon from '@mui/icons-material/EscalatorWarningOutlined';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';

function ResearchHub(props){
    
    const session = props?.session;
    const reporting = session?.reporting;
    const currentAccountID = session?.handler?.data?.currentAccountID;
    const individualData = session?.individual?.data[session?.handler?.data?.currentAccountID];
    const viewType = session?.env?.viewport?.viewType;

    const [numOptions, setNumOptions] = useState(session?.account?.data[currentAccountID]?.permissableUse ? session?.user?.data?.permissions?.includes("addressHub") ? session?.user?.data?.permissions?.includes("relativeHub") ? 4 : 3 : session?.user?.data?.permissions?.includes("relativeHub") ? 3 : 2 : 1 );

    const [displayInvalid, setDisplayInvalid] = useState(false);
    const [invalidInputs, setInvalidInputs] = useState([]);
    const [landingPage, setLandingPage] = useState(true);

    const [obitTabSelected, setObitTabSelected] = useState(session?.account?.data[currentAccountID]?.permissableUse ? 'DRH' : 'ORH');
    const [deathSearched, setDeathSearched] = useState(false);
    const [obitSearched, setObitSearched] = useState(false);
    const [addressSearched, setAddressSearched] = useState(false);
    const [relativeSearched, setRelativeSearched] = useState(false);
    const [deathLoading, setDeathLoading] = useState(false);
    const [obitLoading, setObitLoading] = useState(false);
    const [addressLoading, setAddressLoading] = useState(false);
    const [relativeLoading, setRelativeLoading] = useState(false);
    const [obitSearchAttempted, setObitSearchAttempted] = useState(false);
  
    const [selectedStateObit, setSelectedStateObit] = useState({ showMenu: false });
    const [selectedState, setSelectedState] = useState({
        showMenu : false
    });

    const [deathResData, setDeathResData] = useState({});
    const [obitResData, setObitResData] = useState({});
    const [addressResData, setAddressResData] = useState([]);
    const [relativeResData, setRelativeResData] = useState([]);

    const [relativeStatusCode, setRelativeStatusCode] = useState(null);
    const [addressStatusCode, setAddressStatusCode] = useState(null);

    // {
    //     "city": "BRIDGEWATER",
    //     "full": "120 DEERFIELD DR, BRIDGEWATER, MA 02324-2388",
    //     "latitude": 41.961537235,
    //     "longitude": -70.98874159,
    //     "state": "MA",
    //     "street": "DEERFIELD",
    //     "streetNumber": "120",
    //     "streetSuffix": "DR",
    //     "zip": "02324",
    //     "zip4": "2388"
    // }
    
    const [currentObitRow, setCurrentObitRow] = useState(0);

    const [currentAddressRow, setCurrentAddressRow] = useState(0);

    const [currentRelativeRow, setCurrentRelativeRow] = useState(0);

    const obitString = useRef(null);

    // const data = session?.research?.data?.inputData;
    // const setData = session?.research?.functions?.setInputData;

    const [data, setData] = useState({
        first_name: '',
        middle_name: '',
        last_name: '',
        ssn: '',
        dob: '',
        state: '',
    })

    const requiredFilled = () =>{
        if(obitTabSelected === 'ORH'){
            if(data?.first_name !== '' && data?.last_name !== '' && data?.dob !== ''){
                return true;
            }else{
                return false;
            }
        }
        else if(obitTabSelected === 'DRH'){
            if(data?.first_name !== '' && data?.last_name !== '' && data?.dob !== '' && data?.ssn !== ''){
                return true;
            }else{
                return false;
            }                                             
        }        
        else if(obitTabSelected === 'ARH'){
            if(data?.first_name !== '' && data?.last_name !== '' && data?.dob !== '' && data?.ssn !== ''){
                return true;
            }else{
                return false;
            }                                             
        }            
        else if(obitTabSelected === 'RRH'){
            if(data?.first_name !== '' && data?.last_name !== '' && data?.dob !== '' && data?.ssn !== ''){
                return true;
            }else{
                return false;
            }                                             
        }           
    }                                                                                 
                                                                                                                         
    const validateInputs = () => {
        let validInput = true;
        let invalidInputsArr = [];
        // Allow SSNs with or without dashes
        if ((obitTabSelected === 'DRH' || obitTabSelected === 'ARH' || obitTabSelected === 'RRH') && !(/^\d{9}$|^\d{3}-\d{2}-\d{4}$/.test(data?.ssn))) {
            validInput = false;
            invalidInputsArr.push("ssn");
        }
        if (!/^[A-Za-z-' ]+$/.test(data?.first_name)) {
            validInput = false;
            invalidInputsArr.push("first_name");
        }
        if (!/^[A-Za-z-' ]+$/.test(data?.last_name)) {
            validInput = false;
            invalidInputsArr.push("last_name");
        }
        setInvalidInputs(invalidInputsArr);
        return validInput;
    }

    const getHostName = url => {
        try {
            return new URL(url).hostname;
        } catch (e) {
            return undefined;
        }
    };

    const resultData = [
        { icon: <PersonIcon />, label: 'Name:', value: 'John M. Smith' },
        { icon: <VerifiedIcon />, label: 'Source:', value: 'Obituary' },
        { icon: <AssuredWorkloadIcon />, label: 'DOD:', value: '04/23/2014' },
        { icon: <FingerprintIcon />, label: 'SSN:', value: '***-***-4153' },
        { icon: <CalendarMonthIcon />, label: 'DOB:', value: '03/12/1945' }, 
    ];
    const obitResultData = [
        { icon: <PersonIcon />, label: 'Name:', value: 'John M. Smith' },
        { icon: <AssuredWorkloadIcon />, label: 'DOD:', value: '04/23/2014' },
        { icon: <CalendarMonthIcon />, label: 'DOB:', value: '03/12/1945' },
        { icon: <LocationCityIcon />, label: 'City:', value: 'Orlando' },
        { icon: <LocationOnIcon />, label: 'State:', value: 'Florida' },
        { icon: <PushPinIcon />, label: 'Zip:', value: '32321' },
    ];

    useEffect(() => {
        const approvedAttributes = ["city", "dob", "dod", "first_name", "last_name", "middle_name", "state", "zip_code"];

        if (obitString.current && obitResData[currentObitRow]?.text_content) {
            // Normalize whitespace in obitStringHTML
            let obitStringHTML = obitResData[currentObitRow]?.text_content.replace(/\s+/g, ' ');

            if (obitResData[currentObitRow]?.dob && obitResData[currentObitRow]?.dod) {
                const age = calculateAge(obitResData[currentObitRow]?.dob, obitResData[currentObitRow]?.dod).toString();
                // Enhanced pattern to include a lookbehind for a space or start of line, and a lookahead for a space, end of line, or non-numeric character
                const agePattern = new RegExp(`(?<=^|\\s)${escapeRegExp(age)}(?=$|\\s|\\D)`, 'gi');
    
                if (agePattern.test(obitStringHTML)) {
                    obitStringHTML = obitStringHTML.replace(agePattern, `<span class="matchingText bR bold s e">${age}</span>`);
                }
            }

            approvedAttributes.forEach(key => {
                let values = [obitResData[currentObitRow]?.[key]];
                // console.log(values);
                // Check if the attribute is a date
                if (key === 'dob' || key === 'dod') {
                    values = formatDateVariations(obitResData[currentObitRow]?.[key]);
                }

                values.forEach(value => {
                    if (value && typeof value === 'string') {
                        const normalizedValue = value.replace(/\s+/g, ' ');
                        // Use word boundaries (\b) around the normalized value to match only full words
                        let pattern;
                        if(key === 'state'){
                            pattern = new RegExp(`\\b${escapeRegExp(normalizedValue)}\\b`, 'g');
                        }else{
                            pattern = new RegExp(`\\b${escapeRegExp(normalizedValue)}\\b`, 'gi');
                        }
                        // Replace the matched value with bold tags
                        if (pattern.test(obitStringHTML)) { // Test if the pattern exists in the obitStringHTML before replacing
                            if(key === 'state' && value.length === 2 && value !== value.toUpperCase()){                          
                            }else{
                                obitStringHTML = obitStringHTML.replace(pattern, `<span class="matchingText bR bold s e">${normalizedValue}</span>`);
                            }                    
                        }
                    }
                });
            });

            obitString.current.innerHTML = `<section>${obitStringHTML}</section>`;
        }
    }, [obitSearched, currentObitRow, obitLoading, obitTabSelected]); 

    function calculateAge(dob, dod = null) {
        const birthDate = new Date(dob);
        const endDate = dod ? new Date(dod) : new Date(); // Use the provided date of death or the current date if dod is not provided
        let age = endDate.getFullYear() - birthDate.getFullYear();
        const monthDifference = endDate.getMonth() - birthDate.getMonth();
    
        if (monthDifference < 0 || (monthDifference === 0 && endDate.getDate() < birthDate.getDate())) {
            age--;
        }
    
        return age;
    }

    function escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    const formatDateVariations = (dateString) => {
        if (!dateString || !dateString.includes('-')) {
            return [];
        }

        const [year, month, day] = dateString.split("-").map(Number);
        const date = new Date(year, month - 1, day);
    
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const longDate = date.toLocaleDateString('en-US', options);
    
        const shortYear = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear().toString().substr(-2)}`;
        const fullYear = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    
        return [longDate, shortYear, fullYear];
    };

    function deathSearch(){
        setDeathLoading(true);
        const sessionUserString = localStorage.getItem('sessionUser');
        let sessionUser = null;
        if (sessionUserString) {
            sessionUser = JSON.parse(sessionUserString);
        }
        const paramVals = {
            first_name: data?.first_name,
            middle_name: data?.middle_name,
            last_name: data?.last_name,
            dob: data?.dob,
            ssn: data?.ssn,
            state: data?.state,
            accountID: sessionUser.accountID
        };
        session?.env?.functions?.buildFetchRequest("search/records", paramVals, null, null, "POST")
          .then(response => response.json())
          .then(resData => {
            
            if(resData.status === 200){
                setDeathResData(resData?.result?.data);              
                setDeathLoading(false);
            }else{
                //setErrorStatus(resData.message);
            }
        });
    }

    function obitSearch(){
        setObitLoading(true);
        const sessionUserString = localStorage.getItem('sessionUser');
        let sessionUser = null;
        if (sessionUserString) {
            sessionUser = JSON.parse(sessionUserString);
        }
        const paramVals = {
            first_name: data?.first_name,
            middle_name: data?.middle_name,
            last_name: data?.last_name,
            dob: data?.dob,
            city: data?.city,
            state: data?.state,
            zip_code: data?.zip_code,
            accountID: sessionUser.accountID
        };
        session?.env?.functions?.buildFetchRequest("search/records/obit", paramVals, null, null, "POST")
          .then(response => response.json())
          .then(resData => {
            
            if(resData.status === 200){
                setObitResData(resData?.result?.data);
                setObitLoading(false);
            }else{
                if(resData.status === 500){
                    if(!obitSearchAttempted){
                        obitSearch();
                        setObitSearchAttempted(true);
                    }
                    
                }
            }
        });
    }

    function addressSearch(){
        setAddressLoading(true);
        const sessionUserString = localStorage.getItem('sessionUser');
        let sessionUser = null;
        if (sessionUserString) {
            sessionUser = JSON.parse(sessionUserString);
        }
        const paramVals = {
            first_name: data?.first_name,
            middle_name: data?.middle_name,
            last_name: data?.last_name,
            dob: data?.dob,
            ssn: data?.ssn,
            city: data?.city,
            state: data?.state,
            accountID: sessionUser.accountID
        };
        session?.env?.functions?.buildFetchRequest("search/records/address", paramVals, null, null, "POST")
          .then(response => response.json())
          .then(resData => {
            
            if(resData.result.status === 200){
                setAddressResData(resData?.result?.data);              
                setAddressLoading(false);
            }else if(resData.result.status === 201){
                setAddressResData(resData?.result?.data);              
                setAddressLoading(false);
                setAddressStatusCode(201);
                //setErrorStatus(resData.message);
            }else if(resData.result.status === 404){
                setAddressResData(resData?.result?.data); 
                setAddressLoading(false);
                setAddressStatusCode(404);
                //setErrorStatus(resData.message);
            }else{
                setAddressLoading(false);
                //setErrorStatus(resData.message);
            }
        });
    }

    function relativeSearch(){
        setRelativeLoading(true);
        const sessionUserString = localStorage.getItem('sessionUser');
        let sessionUser = null;
        if (sessionUserString) {
            sessionUser = JSON.parse(sessionUserString);
        }
        const paramVals = {
            first_name: data?.first_name,
            middle_name: data?.middle_name,
            last_name: data?.last_name,
            dob: data?.dob,
            ssn: data?.ssn,
            city: data?.city,
            state: data?.state,
            accountID: sessionUser.accountID
        };
        session?.env?.functions?.buildFetchRequest("search/records/relative", paramVals, null, null, "POST")
          .then(response => response.json())
          .then(resData => {
            
            if(resData.result.status === 200){
                setRelativeResData(resData?.result?.data);              
                setRelativeLoading(false);
            }else if(resData.result.status === 201){
                setRelativeResData(resData?.result?.data);              
                setRelativeLoading(false);
                setRelativeStatusCode(201);
            }else if(resData.result.status === 404){
                setRelativeResData(resData?.result?.data);
                setRelativeLoading(false);
                setRelativeStatusCode(404);
                //setErrorStatus(resData.message);
            }else{
                //setErrorStatus(resData.message);
            }
        });
    }

    const exportFamilyDataPDF = (relativeResData, data) => {
        const doc = new jsPDF();
        const marginLeft = 10;
        const marginTop = 50;
        const lineHeight = 8;
        const pageWidth = 300;
        const pageHeight = doc.internal.pageSize.height;
        const contentWidth = pageWidth - 2 * marginLeft;
        const contentHeightLimit = pageHeight - marginTop - 20; // Usable height for content
        let cursorY = marginTop;
        let currentPage = 1;
    
        // Draw Header Function
        const drawHeader = (currentPage) => {
            if (ABLLogo) {
                const logoWidth = 50; // Set desired logo width
                const logoHeight = 40; // Set desired logo height (adjust as needed)
                const logoX = (pageWidth / 4) + 0; // Position the logo at the left margin
                const logoY = 0; // Position the logo at the top of the page
                doc.addImage(ABLLogo, "PNG", logoX, logoY, logoWidth, logoHeight);
            }
            const headerText = `${data?.first_name.toUpperCase() + " " + data?.last_name.toUpperCase()} Relative Data`;
            const headerFontSize = 14;
            doc.setFontSize(headerFontSize);
            doc.text(headerText, marginLeft, 40); // Header at the top
        };
    
        // Draw Footer Function
        const drawFooter = (currentPage) => {
            const footerText = `Page ${currentPage}`;
            const footerFontSize = 10;
            doc.setFontSize(footerFontSize);
            doc.text(footerText, marginLeft, pageHeight - 10); // Footer at the bottom
        };
    
        // Draw Header and Footer on the first page
        drawHeader(currentPage);
        drawFooter(currentPage);
    
        relativeResData?.forEach((person) => {
            const personText = `
            Name: ${person.firstName} ${person.lastName}
            Date of Birth: ${person.dob}
            Phone: ${person.phone}
            Relation: ${person.relation}
            `;
            
            // Split text into lines that fit within the content width
            const textLines = doc.splitTextToSize(personText.trim(), contentWidth);
    
            textLines?.forEach((line) => {
                if (cursorY + lineHeight > contentHeightLimit) {
                    // Start a new page
                    doc.addPage();
                    currentPage++;
                    cursorY = marginTop;
    
                    // Draw Header and Footer on new page
                    drawHeader(currentPage);
                    drawFooter(currentPage);
                }
    
                doc?.text(line, marginLeft, cursorY);
                cursorY += lineHeight; // Move down for the next line
            });
    
            cursorY += lineHeight; // Add spacing between entries
        });
    
        // Save the PDF
        const fileName = `${data?.first_name.toUpperCase() + data?.last_name.toUpperCase()}RelativeData.pdf`;
        doc.save(fileName);
    };

    useEffect(()=>{
        // if(addressResData.length < 6){ 
        //     setAddressResData((prevData) => [...prevData, ...(
        //         [{
        //         "city": "BRIDGEWATER",
        //         "full": "120 DEERFIELD DR, BRIDGEWATER, MA 02324-2388", 
        //         "latitude": 39.961537235,
        //         "longitude": -70.98874159,
        //         "state": "MA",
        //         "street": "DEERFIELD",
        //         "streetNumber": "120",
        //         "streetSuffix": "DR",
        //         "zip": "02324",
        //         "zip4": "2388"
        //         }
        //         ]
        //      || []
        //      )]);
        // }
        
    }, [addressResData]);

    return (

            (landingPage && (numOptions > 1)) ? 
            <div className="researchHubLandingContainer f g bR" >
                <div className='landingPageContainer cC f g'>
                    <div className='researchHubTitle bR bold gR1 cC f' style={{ fontSize: '20px' }}>Research Hub</div>
                    <div className='bold gR2 cC f'><div className='cC dG'  style={{ fontSize: '18px' }}>Select the Type of Information You Are Searching For <SearchTwoToneIcon style={{ color: '#00897b' }}/> </div></div>
                    <div className='landingPageOptions g cC f gR3' style={{ gridTemplateColumns: `repeat(${numOptions}, 1fr)` }}>
                         {session?.account?.data[currentAccountID]?.permissableUse &&
                            <div className='landingPageTile bold bR p g cC ' onClick={()=>{setObitTabSelected('DRH');setLandingPage(false);}}>
                                Death Record Hub <AssuredWorkloadIcon style={{fontSize:'100px', color: '#00897b'}}/> Search state death records
                            </div>
                         }
                            <div className='landingPageTile bold bR p g cC ' onClick={()=>{setObitTabSelected('ORH');setLandingPage(false);}}>
                                Obituary Research Hub <NewspaperIcon style={{fontSize:'100px', color: '#00897b'}}/> Search obituaries of an individual
                            </div>
                         {session?.user?.data?.permissions?.includes("addressHub") &&
                            <div className='landingPageTile bold bR p g cC ' onClick={()=>{setObitTabSelected('ARH');setLandingPage(false);}}>
                                Address Research Hub <HomeIcon style={{fontSize:'100px', color: '#00897b'}}/> Search addresses of an individual
                            </div>
                         }
                         {session?.user?.data?.permissions?.includes("relativeHub") && 
                            <div className='landingPageTile bold bR p g cC ' onClick={()=>{setObitTabSelected('RRH');setLandingPage(false);}}>
                                Relative Research Hub <EscalatorWarningOutlinedIcon style={{fontSize:'100px', color: '#00897b'}}/> Search relatives of an individual
                            </div>
                         }
                    </div>
                </div>
                
            </div>
            :

            <div className="researchHubContainer f g bR" >
                {/* <div className='researchHubBox f cC g bR dP'> */}
                <div className='researchHubTabs g gR1 gC1 cL'>
                    <div className="uploadViewBar f gC1 dP"> 
                        {session?.account?.data[currentAccountID]?.permissableUse &&               
                            <div className="gC1">
                                <ViewBarBtn
                                    isActive={obitTabSelected === 'DRH'}
                                    onClick={() => setObitTabSelected('DRH')}
                                    // label= {viewType === 'mobile' ? "DeathRH" : "Death Record Hub"}
                                    label= {"DRH"}
                                    icon={viewType === "full" ? <AssuredWorkloadIcon/> : ''}
                                    alertType={"Status"}
                                    // viewType={viewType}
                                    session={session}
                                    currentPolicy={props?.currentPolicy}
                                />
                            </div>
                        }                        
                        <div className="gC2">
                            <ViewBarBtn                                   
                                isActive={obitTabSelected === 'ORH'}
                                onClick={() => setObitTabSelected('ORH')}
                                label= {"ORH"}
                                // label= {viewType === 'mobile' ? "ObituaryRH" : "Obituary Research Hub"}
                                icon={viewType === "full" ? <NewspaperIcon/> : ''}
                                // viewType={viewType}
                                session={session}
                            />
                        </div>        
                        {session?.user?.data?.permissions?.includes("addressHub") &&
                            <div className="gC3">
                                <ViewBarBtn                                   
                                    isActive={obitTabSelected === 'ARH'}
                                    onClick={() => setObitTabSelected('ARH')}
                                    label= {"ARH"}
                                    // label= {viewType === 'mobile' ? "AddressRH" : "Address Research Hub"}
                                    icon={viewType === "full" ? <HomeIcon/> : ''}
                                    // viewType={viewType}
                                    session={session}
                                />
                            </div>   
                        }       
                        {session?.user?.data?.permissions?.includes("relativeHub") &&
                            <div className="gC4">
                                <ViewBarBtn                                   
                                    isActive={obitTabSelected === 'RRH'}
                                    onClick={() => setObitTabSelected('RRH')}
                                    label= {"RRH"}
                                    // label= {viewType === 'mobile' ? "AddressRH" : "Address Research Hub"}
                                    icon={viewType === "full" ? <EscalatorWarningOutlinedIcon/> : ''}
                                    // viewType={viewType}
                                    session={session}
                                />
                            </div>   
                        }     
                    </div>
                </div>
                <div className='cC gR1 gC1 dG'>
                    <div className=''>
                        {
                            obitTabSelected === 'DRH' ?
                            <div className='researchHubTitle bold dP bR'>Death Record Hub</div>
                            :
                            obitTabSelected === 'ORH' ?
                            <div className='researchHubTitle bold dP bR'>Obituary Research Hub</div>
                            :
                            obitTabSelected === 'ARH' ?
                            <div className='researchHubTitle bold dP bR'>Address Research Hub</div>
                            :
                            obitTabSelected === 'RRH' &&
                            <div className='researchHubTitle bold dP bR'>Relative Research Hub</div>
                        }
                    </div>
                </div>
                <div className={`f gR2 g bR dP ${(obitTabSelected === 'ARH' || obitTabSelected === 'RRH') ? 'addressHubContent' : 'researchHubContent'}`}>
                    {(obitTabSelected !== 'ARH' && obitTabSelected !== 'RRH') ? 
                        <div className='researchHubInputs f g bR gR1'>  
                            <div className='cC gR1'>
                                <div className='inputTitle dP bR cC'>
                                    {displayInvalid ? 
                                        <div className='cC dG'>
                                            <div>
                                                <InfoIcon style={{ color: 'red' }}/>
                                            </div>
                                            <div className='redText'>
                                                Fix invalid inputs to search
                                            </div>
                                        </div>
                                        :
                                        <div className='cC dG'>
                                            
                                            <InfoIcon style={{ color: 'dimgray' }}/>
                                            
                                            <div>
                                                Enter an Individual to Search
                                            </div>
                                        </div>
                                    }
                                </div>

                            </div>
                            <div className={`cC gR2 dG dP f g ${obitTabSelected ==='ARH' || obitTabSelected === 'RRH' ? 'addressInputRow1' : 'inputRow1'}`}>
                                <div className='gC2 f'>
                                    {obitTabSelected ==='DRH' ?
                                        <InputField
                                            value={data?.first_name}
                                            placeholder={"First Name *"}
                                            functionPointer={session?.env?.functions?.updateInput}
                                            name="first_name"
                                            setter={setData}
                                            {...(displayInvalid && invalidInputs.includes('first_name') ? { error:' '} : {})}
                                        />   
                                        :
                                        obitTabSelected ==='ORH' ?
                                            <InputField
                                                value={data?.first_name}
                                                placeholder={"First Name *"}
                                                functionPointer={session?.env?.functions?.updateInput}
                                                name="first_name"
                                                setter={setData}
                                                {...(displayInvalid && invalidInputs.includes('first_name') ? { error:' '} : {})}
                                            />
                                        :
                                        null   
                                    }
                                </div>
                                <div className='gC4 f'>
                                    {obitTabSelected === 'DRH' ?
                                        <InputField
                                                value={data?.middle_name}
                                                placeholder={"Middle Name"}
                                                functionPointer={session?.env?.functions?.updateInput}
                                                name="middle_name"
                                                setter={setData}
                                        />
                                        :
                                        obitTabSelected ==='ORH' ?
                                            <InputField
                                                    value={data?.middle_name}
                                                    placeholder={"Middle Name"}
                                                    functionPointer={session?.env?.functions?.updateInput}
                                                    name="middle_name"
                                                    setter={setData}
                                            />
                                        :
                                        (obitTabSelected ==='ARH' || obitTabSelected ==='RRH') &&
                                            <InputField
                                                value={data?.ssn}
                                                placeholder={viewType !== "full" ? "SSN *" : "Social Security Number *"}
                                                functionPointer={session?.env?.functions?.updateInput}
                                                name="ssn"
                                                setter={setData}
                                                {...(displayInvalid && invalidInputs.includes('ssn') ? { error:' '} : {})}
                                            />                                          
                                    }
                                </div>
                                <div className='gC6 f'>
                                    {obitTabSelected === 'DRH' ?
                                        <InputField
                                            value={data?.last_name}
                                            placeholder={"Last Name *"}
                                            functionPointer={session?.env?.functions?.updateInput}
                                            name="last_name"
                                            setter={setData}
                                            {...(displayInvalid && invalidInputs.includes('last_name') ? { error:' '} : {})}
                                        />
                                        :
                                        obitTabSelected ==='ORH' ?
                                            <InputField
                                                value={data?.last_name}
                                                placeholder={"Last Name *"}
                                                functionPointer={session?.env?.functions?.updateInput}
                                                name="last_name"
                                                setter={setData}
                                                {...(displayInvalid && invalidInputs.includes('last_name') ? { error:' '} : {})}
                                            />
                                            :
                                            null
                                    }
                                </div>
                            </div>
                            <div className={`bC gR3 dG dP g ${obitTabSelected === 'DRH' ? 'inputRow2' : obitTabSelected === 'ORH' ? 'obitInputRow2' : 'addressInputRow2'}`}>
                                <div className='dobLabel C inputFont gC2'>{viewType ==="mobile" ? "DOB*" : "Date of Birth*" }</div>
                                <div className='gC3 f dobField'>
                                    {obitTabSelected === 'DRH' ?
                                        <InputField                                        
                                            value={data?.dob}
                                            type="date" 
                                            functionPointer={session?.env?.functions?.updateInput}
                                            name="dob"
                                            setter={setData}
                                        />
                                        :
                                        <InputField                                        
                                            value={data?.dob}
                                            type="date" 
                                            functionPointer={session?.env?.functions?.updateInput}
                                            name="dob"
                                            setter={setData}
                                        />
                                    }
                                </div>
                                <div className='gC5 f' style={{ minWidth: '55px'}}>
                                    {obitTabSelected === 'DRH' ?
                                            <InputField
                                                value={data?.ssn}
                                                placeholder={viewType !== "full" ? "SSN *" : "Social Security Number *"}
                                                functionPointer={session?.env?.functions?.updateInput}
                                                name="ssn"
                                                setter={setData}
                                                {...(displayInvalid && invalidInputs.includes('ssn') ? { error:' '} : {})}
                                            />
                                        :       
                                        obitTabSelected === 'ORH' ?
                                            <InputField
                                                value={data?.city}
                                                placeholder={"City"}
                                                functionPointer={session?.env?.functions?.updateInput}
                                                name="city"
                                                setter={setData}
                                            />
                                            :
                                            <InputField
                                                value={data?.city}
                                                placeholder={"City"}
                                                functionPointer={session?.env?.functions?.updateInput}
                                                name="city"
                                                setter={setData}
                                            />
                                    }
                                </div>
                                {obitTabSelected === 'ORH' ?
                                    <div className='gC7 f stateDropdown stateInputBox g'>
                                        <div className='gR2'>
                                            <Dropdown 
                                                list={session?.individual.data[session?.handler?.data?.currentAccountID].individualAttributesData?.state.list}
                                                setShowMenu={(input) => {
                                                    setSelectedState((prev) => ({ 
                                                        showMenu: input ?? !prev.showMenu, 
                                                    }));
                                                }}
                                                default={"State"}
                                                showMenu={selectedState?.showMenu}
                                                valuePointer="state"
                                                onClick={(value)=>{
                                                    if (value === "State") {
                                                        setData(prevData => ({
                                                            ...prevData,
                                                            state: '',
                                                        }));
                                                    } else {
                                                        setData(prevData => ({
                                                            ...prevData,
                                                            state: value,
                                                        })); 
                                                    }
                                                    setSelectedState((prev) => ({ ...prev, showMenu: false }));
                                                }}
                                            />
                                        </div>
                                    </div>
                                    :
                                    <div className='gC7 f stateDropdown stateInputBox g'>
                                        <div className='gR2'>
                                        <Dropdown 
                                            list={session?.individual.data[session?.handler?.data?.currentAccountID].individualAttributesData?.state.list}
                                            setShowMenu={(input) => {
                                                setSelectedState((prev) => ({ 
                                                    showMenu: input ?? !prev.showMenu, 
                                                }));
                                            }}
                                            default={"State"}
                                            showMenu={selectedState?.showMenu}
                                            valuePointer="state"
                                            onClick={(value)=>{
                                                if (value === "State") {
                                                    setData(prevData => ({
                                                        ...prevData,
                                                        state: '',
                                                    }));
                                                } else {
                                                    setData(prevData => ({
                                                        ...prevData,
                                                        state: value,
                                                    })); 
                                                }
                                                setSelectedState((prev) => ({ ...prev, showMenu: false }));
                                            }}
                                        />
                                        </div>
                                    </div>
                                }
                                {obitTabSelected === 'ORH' &&
                                    <div className='gC9 f' style={{ minWidth: '45px'}}>
                                            <InputField
                                                value={data?.zip_code}
                                                placeholder={viewType === "mobile" ? "Zip" : "Zip Code"}
                                                functionPointer={session?.env?.functions?.updateInput}
                                                name="zip_code"
                                                setter={setData}
                                            />
                                    </div>
                                }
                                
                            </div>
                            <div className='cC gR4'>
                                <div 
                                    className={`inputSearch btnWIcon ${requiredFilled() ? '' : 'inactive'} cC dP bR p g`}
                                    onClick={() => 
                                        { 
                                            if(requiredFilled()){
                                                if (validateInputs()) 
                                                { 
                                                    setDisplayInvalid(false);
                                                    setInvalidInputs([]);
                                                    if(obitTabSelected === 'DRH'){setDeathSearched(true); deathSearch();}else{setObitSearched(true); obitSearch(); setCurrentObitRow(0);} 
                                                }  
                                                else{
                                                    setDisplayInvalid(true)                                                     
                                                }
                                            }
                                        }}
                                >
                                    Search <SearchTwoToneIcon/>
                                </div>
                            </div>
                        </div>
                        :
                        <div className='addressHubInputs f g bR gR1'>
                            <div className='cC gR1'>
                                <div className='inputTitle dP bR cC'>
                                    {displayInvalid ? 
                                        <div className='cC dG'>
                                            <div>
                                                <InfoIcon style={{ color: 'red' }}/>
                                            </div>
                                            <div className='redText'>
                                                Fix invalid inputs to search
                                            </div>
                                        </div>
                                        :
                                        <div className='cC dG'>
                                            
                                            <InfoIcon style={{ color: 'dimgray' }}/>
                                            
                                            <div>
                                                Enter an Individual to Search
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className='cC gR2 dG dP f g addressInputRow1'>
                                <div className='gC4 f'>
                                        <InputField
                                            value={data?.ssn}
                                            placeholder={"Social Security Number *"}
                                            functionPointer={session?.env?.functions?.updateInput}
                                            name="ssn"
                                            setter={setData}
                                            {...(displayInvalid && invalidInputs.includes('ssn') ? { error:' '} : {})}
                                        />
                                </div>
                            </div>
                            <div className='cC gR3 dG dP f g addressInputRow2'>
                                <div className='gC2 f'>
                                        <InputField
                                            value={data?.first_name}
                                            placeholder={"First Name *"}
                                            functionPointer={session?.env?.functions?.updateInput}
                                            name="first_name"
                                            setter={setData}
                                            {...(displayInvalid && invalidInputs.includes('first_name') ? { error:' '} : {})}
                                        />   
                                </div>
                                <div className='gC4 f'>
                                        <InputField
                                                value={data?.middle_name}
                                                placeholder={"Middle Name"}
                                                functionPointer={session?.env?.functions?.updateInput}
                                                name="middle_name"
                                                setter={setData}
                                        />
                                </div>
                                <div className='gC6 f'>
                                        <InputField
                                            value={data?.last_name}
                                            placeholder={"Last Name *"}
                                            functionPointer={session?.env?.functions?.updateInput}
                                            name="last_name"
                                            setter={setData}
                                            {...(displayInvalid && invalidInputs.includes('last_name') ? { error:' '} : {})}
                                        />
                                </div>
                            </div>
                            <div className='bC gR4 dG dP f g addressInputRow3'>
                                <div className='dobLabel C inputFont gC2'>{viewType ==="mobile" ? "DOB*" : "Date of Birth*" }</div>
                                <div className='gC3 f g dobField'>
                                        <InputField                                        
                                            value={data?.dob}
                                            type="date" 
                                            functionPointer={session?.env?.functions?.updateInput}
                                            name="dob"
                                            setter={setData}
                                        />
                                </div>
                                <div className='gC5 f'>
                                        <InputField
                                            value={data?.city}
                                            placeholder={"City"}
                                            functionPointer={session?.env?.functions?.updateInput}
                                            name="city"
                                            setter={setData}
                                        />
                                </div>
                                <div className='gC7 f stateDropdown stateInputBox g'>
                                        <div className='gR2'>
                                        <Dropdown 
                                            list={session?.individual.data[session?.handler?.data?.currentAccountID].individualAttributesData?.state.list}
                                            setShowMenu={(input) => {
                                                setSelectedState((prev) => ({ 
                                                    showMenu: input ?? !prev.showMenu, 
                                                }));
                                            }}
                                            default={"State"}
                                            showMenu={selectedState?.showMenu}
                                            valuePointer="state"
                                            onClick={(value)=>{
                                                if (value === "State") {
                                                    setData(prevData => ({
                                                        ...prevData,
                                                        state: '',
                                                    }));
                                                } else {
                                                    setData(prevData => ({
                                                        ...prevData,
                                                        state: value,
                                                    })); 
                                                }
                                                setSelectedState((prev) => ({ ...prev, showMenu: false }));
                                            }}
                                        />
                                        </div>
                                    </div>
                            </div>
                            <div className='cC gR5'>
                                <div 
                                    className={`inputSearch btnWIcon ${requiredFilled() ? '' : 'inactive'} cC dP bR p g`}
                                    onClick={() => 
                                        { 
                                            if(requiredFilled()){
                                                if (validateInputs()) 
                                                { 
                                                    setDisplayInvalid(false); 
                                                    setInvalidInputs([]);
                                                    if(obitTabSelected === 'DRH'){setDeathSearched(true); deathSearch();}
                                                    else if(obitTabSelected === 'ORH'){setObitSearched(true); obitSearch(); setCurrentObitRow(0);}
                                                    else if(obitTabSelected === 'ARH'){setAddressSearched(true); addressSearch(); setCurrentAddressRow(0);}
                                                    else if(obitTabSelected === 'RRH'){setRelativeSearched(true); relativeSearch(); setCurrentAddressRow(0);}
                                                }  
                                                else{
                                                    setDisplayInvalid(true)                                                     
                                                }
                                            }
                                        }}
                                >
                                    Search <SearchTwoToneIcon/>
                                </div>
                            </div>
                        </div>
                    }

                    <div  className={`researchHubResults f oA g bR dP gR2 ${obitTabSelected === 'RRH' && relativeSearched ? 'relativeResults' : ''}`}>  
                        { ((obitTabSelected === 'DRH' && !deathSearched && !deathLoading) || (obitTabSelected === 'ORH' && !obitSearched && !obitLoading) || (obitTabSelected === 'ARH' && !addressSearched && !addressLoading) || (obitTabSelected === 'RRH' && !relativeSearched && !relativeLoading) ) ?
                            <div className='empty loading g cC'>
                                {/* <div className='vDivider gR1' style={{width:'3px', height:'100px', backgroundColor:'darkGray'}}></div> */}
                                <div className=''><div className=''><PersonSearchIcon/><div>Search an individual to see results</div></div></div>
                            </div>
                        :
                            <>
                                {(obitTabSelected === 'DRH' && deathSearched && !deathLoading && deathResData !== 0) ?
                                    <div>
                                        {Array.from({ length: deathResData.length }).map((_, index) => (
                                            <div key={index} className='cC dG resultBlock'>
                                                <>
                                                <div className='cC dG'>
                                                    {<PersonIcon/>}
                                                    <div className='bold'>{'Name:'}</div>
                                                    {deathResData[index]?.first_name + " " + deathResData[index]?.middle_name + " " + deathResData[index]?.last_name}
                                                </div>
                                                <div className='cC dG'>
                                                    {<VerifiedIcon/>}
                                                    <div className='bold'>{'Source:'}</div>
                                                    {deathResData[index]?.source}
                                                </div>
                                                <div className='cC dG'>
                                                    {<AssuredWorkloadIcon/>}
                                                    <div className='bold'>{'DOD:'}</div>
                                                    {deathResData[index]?.dod ? session?.env?.functions?.reformatDate(deathResData[index]?.dod) : 'N/A'}
                                                </div>
                                                <div className='cC dG'>
                                                    {<FingerprintIcon/>}
                                                    <div className='bold'>{'SSN:'}</div>
                                                    {deathResData[index]?.ssn}
                                                </div> 
                                                <div className='cC dG'>
                                                    {<CalendarMonthIcon/>}
                                                    <div className='bold'>{'DOB:'}</div>
                                                    {deathResData[index]?.dob ? session?.env?.functions?.reformatDate(deathResData[index]?.dob) : 'N/A'}
                                                </div>                                       
                                                </>
                                            </div>
                                        ))}
                                    </div>
                                :   
                                    (obitTabSelected === 'ORH' && obitSearched && !obitLoading && obitResData.length !== 0) ?
                                        <div className='obituaryResults oA dG g'>
                                            <div className='gC1 obituaryListBox oA f'>
                                                {Array.from({ length: obitResData.length }).map((_, index) => (
                                                    <div key={index} className={`cL dP p g obitResultBlock ${currentObitRow === index ? 'selectedResultBlock' : ''}`} onClick={() => {setCurrentObitRow(index)}}>
                                                        <div className='cC dG gR1 gC1'>
                                                            {<PersonIcon/>}
                                                            <div className='bold'>{"Name: "}</div>
                                                            {obitResData[index]?.first_name + " " + (obitResData[index]?.middle_name ?? "") + " " + obitResData[index]?.last_name}
                                                        </div>
                                                        {(!obitResData[index]?.dod && obitResData[index]?.death_year) ?
                                                        <div className='cC dG gR1 gC2'>
                                                            {<AssuredWorkloadIcon/>}
                                                            <div className='bold'>{'Death Year:'}</div>
                                                            {obitResData[index]?.death_year}
                                                        </div>
                                                        :
                                                        <div className='cC dG gR1 gC2'>
                                                            {<AssuredWorkloadIcon/>}
                                                            <div className='bold'>{'DOD:'}</div>
                                                            {obitResData[index]?.dod ? session?.env?.functions?.reformatDate(obitResData[index]?.dod) : "N/A"}
                                                        </div>
                                                        }
                                                        {(!obitResData[index]?.dob && obitResData[index]?.age) ?
                                                            <div className='cC dG gR1 gC3'>
                                                                {<CalendarMonthIcon/>}
                                                                <div className='bold'>{'Age:'}</div>
                                                                {obitResData[index]?.age}
                                                            </div> 
                                                            :
                                                            <>
                                                            {(!obitResData[index]?.dob && !obitResData[index]?.age && obitResData[index]?.birth_year) ?
                                                            <div className='cC dG gR1 gC3'>
                                                                {<CalendarMonthIcon/>}
                                                                <div className='bold'>{'Birth Year:'}</div>
                                                                {obitResData[index]?.birth_year}
                                                            </div>
                                                            :
                                                            <div className='cC dG gR1 gC3'>
                                                                {<CalendarMonthIcon/>}
                                                                <div className='bold'>{'DOB:'}</div>
                                                                {obitResData[index]?.dob ? session?.env?.functions?.reformatDate(obitResData[index]?.dob) : "N/A"}
                                                            </div>
                                                            }
                                                            </>
                                                        }
                                                        <div className='cC dG gR2 gC1'>
                                                            {<LocationCityIcon/>}
                                                            <div className='bold'>{"City:"}</div>
                                                            {obitResData[index]?.city ?? "N/A"}
                                                        </div>
                                                        <div className='cC dG gR2 gC2'>
                                                            {<LocationOnIcon/>}
                                                            <div className='bold'>{"State:"}</div>
                                                            {obitResData[index]?.state ?? "N/A"}
                                                        </div>
                                                        <div className='cC dG gR2 gC3'>
                                                            {<PushPinIcon/>}
                                                            <div className='bold'>{"Zip:"}</div>
                                                            {obitResData[index]?.zip ?? "N/A"}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className='gC2 bR dP dG g f oA obituaryTextBox'>
                                                <div className='bold'>Obituary Details</div>
                                                <div className='cC obitLinkBlock'><a href={obitResData[currentObitRow]?.url} target="_blank" rel="noreferrer" className="link urlStyle bR cC dG"><LanguageIcon/>{getHostName(obitResData[currentObitRow]?.url)}</a></div>
                                                <div className='obitContentBlock dP'>
                                                    <div className='cC dG gR1'> <ArticleIcon/> <div className='f bold cL'>Content:</div> </div> 
                                                    <div className="obitTextContent" ref={obitString}>{obitResData[currentObitRow]?.text_content}</div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        (obitTabSelected === 'ARH' && addressSearched && !addressLoading && addressResData.length !== 0) ?
                                            <div className='obituaryResults oA dG g'>
                                                <div className='gC1 obituaryListBox oA f'>
                                                    {Array.from({ length: addressResData.length }).map((_, index) => (
                                                        <div key={index} className={`cL dP p g addressResultBlock ${currentAddressRow === index ? 'selectedResultBlock' : ''}`} onClick={() => {setCurrentAddressRow(index)}}>
                                                            <div className='cC dG gR1 gC1'>
                                                                {<PersonIcon/>}
                                                                <div className='bold'>{"Name: "}</div>
                                                                {data?.first_name + " " + (data?.middle_name ?? "") + " " + data?.last_name}
                                                            </div>
                                                            {(addressResData[index]?.full) &&
                                                                <div className='cC dG gR1 gC2'>
                                                                    {<HomeIcon/>}
                                                                    <div className='bold'>{'Address:'}</div>
                                                                    {addressResData[index]?.full}
                                                                </div>
                                                            }
                                                        </div>
                                                    ))}
                                                    
                                                </div>
                                                <div className='gC2 bR dP dG g f oA addressTextBox'>
                                                    <MapComponent
                                                        key = {currentAddressRow}
                                                        latitude = {addressResData[currentAddressRow]?.latitude}
                                                        longitude = {addressResData[currentAddressRow]?.longitude}
                                                    />
                                                </div>
                                            </div>
                                        :
                                        (obitTabSelected === 'RRH' && relativeSearched && !relativeLoading && relativeResData.length !== 0) &&
                                            // Change 0 to index
                                            <div className='dG'>
                                            <div className='relativeExport g btnWIcon bR p cC' onClick={()=>{exportFamilyDataPDF(relativeResData, data)}}><div className='gC2'>Export as PDF</div><IosShareIcon className='gC4'/> </div>
                                            {Array.from({ length: relativeResData.length }).map((_, index) => (
                                                <div key={index} className={`cL dP dG g relativeResultBlock `} onClick={() => {}}>
                                                    <div className='cC dG gR1 gC1'>
                                                        {<PersonIcon/>}
                                                        <div className='bold'>{"Name: "}</div>
                                                        {relativeResData[index]?.firstName +  " " + relativeResData[index]?.lastName }
                                                    </div>
                                                    <div className='cC dG gR1 gC2'>
                                                        {<EscalatorWarningOutlinedIcon/>}
                                                        <div className='bold'>{"Relationship: "}</div>
                                                        {relativeResData[index]?.relation }
                                                    </div>
                                                    <div className='cC dG gR1 gC3'>
                                                        {<CalendarMonthIcon/>}
                                                        <div className='bold'>{"Date of Birth: "}</div>
                                                        {relativeResData[index]?.dob}
                                                    </div>
                                                    <div className='cC dG gR1 gC4'>
                                                        {<LocalPhoneIcon/>}
                                                        <div className='bold'>{"Phone Number: "}</div>
                                                        {relativeResData[index]?.phone}
                                                    </div>
                                                </div>
                                            ))}
                                            </div>
                                }
                                {( !obitLoading && !deathLoading && !addressLoading && !relativeLoading && ((obitSearched && obitTabSelected === 'ORH' && obitResData.length === 0) || (deathSearched && obitTabSelected === 'DRH' && deathResData.length === 0) || (addressSearched && obitTabSelected === 'ARH' && addressResData.length === 0) || (relativeSearched && obitTabSelected === 'RRH' && relativeResData.length === 0)) ) ?
                                    <>
                                        <div className='empty g cC'>
                                            <div className=''><div className=''><SearchOffIcon/><div>
                                                {((obitTabSelected === 'ARH' && addressStatusCode === 201) || (obitTabSelected === 'RRH' && relativeStatusCode === 201)) ? "Input data does not align with social security match." : "No results found! More specifications may be required."}
                                            </div></div></div>
                                        </div>
                                    </>
                                :
                                    <></>
                                }
                            </>
                        }

                        {(obitTabSelected === 'DRH' && deathLoading) && 
                            <div className='cC'>
                                <CircularProgress disableShrink color="success" size={60}/>
                            </div>
                        }
                        {(obitTabSelected === 'ORH' && obitLoading) &&
                            <div className='cC'>
                                <CircularProgress disableShrink color="success" size={60}/>
                            </div>
                        }
                        {(obitTabSelected === 'ARH' && addressLoading) &&
                            <div className='cC'>
                                <CircularProgress disableShrink color="success" size={60}/>
                            </div>
                        }
                        {(obitTabSelected === 'RRH' && relativeLoading) &&
                            <div className='cC'>
                                <CircularProgress disableShrink color="success" size={60}/>
                            </div>
                        }

                    </div>

                </div>
            </div>
    )
}

export default ResearchHub;