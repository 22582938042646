import React, { useEffect, useState } from 'react';

function DisableIndividual(props){

    return(
        <div className='bR f cC g gR1 gC1'>
            <div className='cC bold f gR1 gC1 colSpan2 '>Are You Sure You Want <br/>To Stop Tracking This Individual?</div> 
        </div>
    )

};

export default DisableIndividual