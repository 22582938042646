import React, { useState, useEffect, useRef } from 'react';

import UploadTwoToneIcon from '@mui/icons-material/UploadTwoTone';
import DownloadTwoToneIcon from '@mui/icons-material/DownloadTwoTone';
import PersonAddAltTwoToneIcon from '@mui/icons-material/PersonAddAltTwoTone';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';

function AccountCenter(props){
    
    const session = props?.session;
    const reporting = session?.reporting;
    const currentAccountID = session?.handler?.data?.currentAccountID;
    const individualData = session?.individual?.data[session?.handler?.data?.currentAccountID];
    const viewType = session?.env?.viewport?.viewType;

    /* Data needed from Routes 
        Global
            List of all accounts

        Account Level
            List of all users
            Account permissions
        
        User Level
            User details
            User Permissions
    
       Actions needed from Routes
        Global

        Account Level
            Update account permissions
            Delete users

        User Level
            Update users permissions 
  
    */

    const [currentTab, setCurrentTab] = useState("accounts")

    const [creatingUser, setCreatingUser] = useState(false);
    const [selectedItemIndex, setSelectedItemIndex] = useState(null);

    const [tempUploadPermission, setTempUploadPermission] = useState(null);
    const [tempDownloadPermission, setTempDownloadPermission] = useState(null);
    const [tempCreatePermission, setTempCreatePermission] = useState(null);
    const [tempResearchPermission, setTempResearchPermission] = useState(null);

    const [selectedUser, setSelectedUser] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        last_login: '',
        uploadAllowed: '',
        downloadAllowed: '',
        creationAllowed:'',
        researchAllowed:'',
    })

    const accountList = [
        {name: "Account 1", lastLogin: '12/22/24', accountType: 'Participant'},
        {name: "Account 2", lastLogin: '12/20/24', accountType: "Administrator"},
        {name: "Account 3", lastLogin: '12/21/24', accountType: 'Participant'},
        {name: "Account 4", lastLogin: '12/23/24', accountType: 'Participant'}

    ];

    const accountUsers = [
        {name: "John Smith", lastLogin: '12/22/24', accountType: 'Participant'},
        {name: "John Smith2", lastLogin: '12/20/24', accountType: "Administrator"},
        {name: "John Smith3", lastLogin: '12/21/24', accountType: 'Participant'},
        {name: "John Smith4", lastLogin: '12/23/24', accountType: 'Participant'}


    ];


    const uploadOptions = [
        { value: true, label: "Allowed" },
        { value: false, label: "Not Allowed" },
    ];

    return (
            <div className="researchHubContainer f g bR" >
                <div className='bold cC'>Account Center</div>
                <div className='accountCenterContainer f g'>
                    <div className='accountCenterTabs gR1 g'>
                        <div className='gC1 cC p' onClick={()=>{setCurrentTab("accounts")}}>
                            Accounts
                        </div>
                        <div className='gC2 cC p' onClick={()=>{setCurrentTab("users")}}>
                            Users
                        </div>
                    </div>
                    {currentTab === 'accounts' &&
                        <div className='accountCenterContent gR2 g'>
                            <div className='accountList gC1 g'>
                                <div className='gR1 cC p'>
                                    Create Account +
                                </div>
                                <div className='accountListRows f gR2'>
                                    {accountList?.map((user, index) => (
                                        <div className='accountListRow f cC'>{user.name}</div>
                                    ))}
                                </div>
                            </div>
                            <div className='accountDetails gC2 g'>
                                <div className='gR1 cC bold'>Account Details</div>
                                <div className='userListRows gR2 g'>
                                    {accountUsers?.map((user, index) => (
                                        <div className='userListRow g f'>
                                            {user.name + " " + user.lastLogin + " " + user.accountType }
                                        </div>
                                    ))}
                                 
                                </div>
                                <div className='permissionsContent gR3 g'>
                                    <div className='gR1 cC bold'>
                                        Permissions
                                    </div>
                                    <div className='permissionBtns gR2 g'>
                                        <div className={`header bold cL g ${viewType === "full"  ? 'gC1 gR2' : 'gC1 gR2'} dG`}>
                                            Upload
                                        </div>
                                        <div className={`rowData preferences g ${viewType === "full" ? 'gC1 gR3' : 'gC1 gR3'} s`}>
                                                    <div className="trackBar cC g fR">
                                                        <div className="f cC">
                                                            <UploadTwoToneIcon/>
                                                        </div>
                                                        {uploadOptions.map((option) => (
                                                            <div
                                                                key={option.value}
                                                                className={`option bold f cC dP p${
                                                                    tempUploadPermission === null ? selectedUser?.uploadAllowed === option.value ? " active" : ""
                                                                    :
                                                                    tempUploadPermission === option.value ? " active" : ""
                                                                }`}
                                                                onClick={() => {
                                                                    creatingUser && 
                                                                        setSelectedUser((prev) => ({
                                                                            ...prev,
                                                                            uploadAllowed: option?.value,
                                                                        }));
                                                                    selectedItemIndex !== null &&
                                                                        setTempUploadPermission(option?.value);
                                                                }}
                                                            >
                                                                {option?.label}
                                                            </div>
                                                        ))}
                                                    </div>
                                        </div>
                                        
                                        <div className={`header bold cL g ${viewType === "full"  ? 'gC2 gR2' : 'gC1 gR4'} dG`}>
                                            Download
                                        </div>
                                        <div className={`rowData preferences g ${viewType === "full" ? 'gC2 gR3' : 'gC1 gR5'} s`}>
                                            <div className="trackBar cC g fR">
                                                <div className="f cC">
                                                    <DownloadTwoToneIcon/>
                                                </div>
                                                {uploadOptions.map((option) => (
                                                    <div
                                                        key={option.value}
                                                        className={`option bold f cC dP p${
                                                            tempDownloadPermission === null ? selectedUser?.downloadAllowed === option.value ? " active" : ""
                                                            :
                                                            tempDownloadPermission === option.value ? " active" : ""
                                                        }`}
                                                        onClick={() => {
                                                            creatingUser && 
                                                                setSelectedUser((prev) => ({
                                                                    ...prev,
                                                                    downloadAllowed: option.value,
                                                                }));
                                                            !creatingUser && selectedItemIndex !== null &&
                                                                setTempDownloadPermission(option.value);
                                                        }}
                                                    >
                                                        {option.label}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                        <div className={`header bold cL g ${viewType === "full"  ? 'gC3 gR2' : 'gC3 gR2'} dG`}>
                                            Account Creation
                                        </div>
                                        <div className={`rowData preferences g ${viewType === "full" ? 'gC3 gR3' : 'gC3 gR3'} s`}>
                                            <div className="trackBar cC g fR">
                                                <div className="f cC">
                                                    <PersonAddAltTwoToneIcon/>
                                                </div>
                                                {uploadOptions.map((option) => (
                                                    <div
                                                        key={option.value}
                                                        className={`option bold f cC dP p${
                                                            tempCreatePermission === null ? selectedUser?.creationAllowed === option.value ? " active" : ""
                                                            :
                                                            tempCreatePermission === option.value ? " active" : ""
                                                        }`}
                                                        onClick={() => {
                                                            
                                                            creatingUser && 
                                                                setSelectedUser((prev) => ({
                                                                    ...prev,
                                                                    creationAllowed: option.value,
                                                                }));
                                                            selectedItemIndex !== null &&
                                                                setTempCreatePermission(option.value);
                                                        }}
                                                    >
                                                        {option.label}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        {session?.user?.data?.views?.includes("Research Hub") && 
                                            <>
                                                <div className={`header bold cL g ${viewType === "full"  ? 'gC4 gR2' : 'gC3 gR4'} dG`}>
                                                        Research Hub
                                                </div>
                                                <div className={`rowData preferences g ${viewType === "full" ? 'gC4 gR3' : 'gC3 gR5'} s`}>
                                                    <div className="trackBar cC g fR">
                                                        <div className="f cC">
                                                            <TravelExploreIcon/>
                                                        </div>
                                                        {uploadOptions.map((option) => (
                                                            <div
                                                                key={option.value}
                                                                className={`option bold f cC dP p${
                                                                    tempResearchPermission === null ? selectedUser?.researchAllowed === option.value ? " active" : ""
                                                                    :
                                                                    tempResearchPermission === option.value ? " active" : ""
                                                                }`}
                                                                onClick={() => {    
                                                                    creatingUser && 
                                                                        setSelectedUser((prev) => ({
                                                                            ...prev,
                                                                            researchAllowed: option.value,
                                                                        }));
                                                                    selectedItemIndex !== null &&
                                                                        setTempResearchPermission(option.value);
                                                                }}                                                       
                                                            >
                                                                {option.label}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>

                                    <div className='permissionBtns gR3 g'>
                                        <div className={`header bold cL g ${viewType === "full"  ? 'gC1 gR4' : 'gC1 gR6'} dG`}>
                                            Account Creation
                                        </div>
                                        <div className={`rowData preferences g ${viewType === "full" ? 'gC1 gR5' : 'gC1 gR7'} s`}>
                                            <div className="trackBar cC g fR">
                                                <div className="f cC">
                                                    <PersonAddAltTwoToneIcon/>
                                                </div>
                                                {uploadOptions.map((option) => (
                                                    <div
                                                        key={option.value}
                                                        className={`option bold f cC dP p${
                                                            tempCreatePermission === null ? selectedUser?.creationAllowed === option.value ? " active" : ""
                                                            :
                                                            tempCreatePermission === option.value ? " active" : ""
                                                        }`}
                                                        onClick={() => {
                                                            
                                                            creatingUser && 
                                                                setSelectedUser((prev) => ({
                                                                    ...prev,
                                                                    creationAllowed: option.value,
                                                                }));
                                                            selectedItemIndex !== null &&
                                                                setTempCreatePermission(option.value);
                                                        }}
                                                    >
                                                        {option.label}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        {session?.user?.data?.views?.includes("Research Hub") && 
                                            <>
                                                <div className={`header bold cL g ${viewType === "full"  ? 'gC2 gR4' : 'gC1 gR8'} dG`}>
                                                        Research Hub
                                                </div>
                                                <div className={`rowData preferences g ${viewType === "full" ? 'gC2 gR5' : 'gC1 gR9'} s`}>
                                                    <div className="trackBar cC g fR">
                                                        <div className="f cC">
                                                            <TravelExploreIcon/>
                                                        </div>
                                                        {uploadOptions.map((option) => (
                                                            <div
                                                                key={option.value}
                                                                className={`option bold f cC dP p${
                                                                    tempResearchPermission === null ? selectedUser?.researchAllowed === option.value ? " active" : ""
                                                                    :
                                                                    tempResearchPermission === option.value ? " active" : ""
                                                                }`}
                                                                onClick={() => {    
                                                                    creatingUser && 
                                                                        setSelectedUser((prev) => ({
                                                                            ...prev,
                                                                            researchAllowed: option.value,
                                                                        }));
                                                                    selectedItemIndex !== null &&
                                                                        setTempResearchPermission(option.value);
                                                                }}                                                       
                                                            >
                                                                {option.label}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    }    

                    {currentTab === "users" &&
                           <div className='accountCenterContent gR2 g'>
                           <div className='accountList gC1 g'>
                               <div className='gR1 cC p'>
                                   Create New User +
                               </div>
                               <div className='accountListRows gR2 g'>
                                   
                               </div>
                           </div>
                           <div className='accountDetails gC2 g'>
                               <div className='gR1 cC bold'>User Details</div>
                               <div className='userListRows gR2 g'>
                                   <div className=''>Row 1</div>
                                   <div className=''>Row 2</div>
                                   <div className=''>Row 3</div>
                                   <div className=''>Row 4</div>
                                   <div className=''>Row 5</div>
                                   <div className=''>Row 6</div>
                                   <div className=''>Row 7</div>
                                   <div className=''>Row 8</div>
                               </div>
                               <div className='permissionsContent gR3 g'>
                                   <div className='gR1 cC bold'>
                                       Permissions
                                   </div>
                                   <div className='permissionBtns gR2 g'>
                                       <div className={`header bold cL g ${viewType === "full"  ? 'gC1 gR2' : 'gC1 gR2'} dG`}>
                                           Upload
                                       </div>
                                       <div className={`rowData preferences g ${viewType === "full" ? 'gC1 gR3' : 'gC1 gR3'} s`}>
                                                   <div className="trackBar cC g fR">
                                                       <div className="f cC">
                                                           <UploadTwoToneIcon/>
                                                       </div>
                                                       {uploadOptions.map((option) => (
                                                           <div
                                                               key={option.value}
                                                               className={`option bold f cC dP p${
                                                                   tempUploadPermission === null ? selectedUser?.uploadAllowed === option.value ? " active" : ""
                                                                   :
                                                                   tempUploadPermission === option.value ? " active" : ""
                                                               }`}
                                                               onClick={() => {
                                                                   creatingUser && 
                                                                       setSelectedUser((prev) => ({
                                                                           ...prev,
                                                                           uploadAllowed: option?.value,
                                                                       }));
                                                                   selectedItemIndex !== null &&
                                                                       setTempUploadPermission(option?.value);
                                                               }}
                                                           >
                                                               {option?.label}
                                                           </div>
                                                       ))}
                                                   </div>
                                       </div>
                                       
                                       <div className={`header bold cL g ${viewType === "full"  ? 'gC2 gR2' : 'gC1 gR4'} dG`}>
                                           Download
                                       </div>
                                       <div className={`rowData preferences g ${viewType === "full" ? 'gC2 gR3' : 'gC1 gR5'} s`}>
                                           <div className="trackBar cC g fR">
                                               <div className="f cC">
                                                   <DownloadTwoToneIcon/>
                                               </div>
                                               {uploadOptions.map((option) => (
                                                   <div
                                                       key={option.value}
                                                       className={`option bold f cC dP p${
                                                           tempDownloadPermission === null ? selectedUser?.downloadAllowed === option.value ? " active" : ""
                                                           :
                                                           tempDownloadPermission === option.value ? " active" : ""
                                                       }`}
                                                       onClick={() => {
                                                           creatingUser && 
                                                               setSelectedUser((prev) => ({
                                                                   ...prev,
                                                                   downloadAllowed: option.value,
                                                               }));
                                                           !creatingUser && selectedItemIndex !== null &&
                                                               setTempDownloadPermission(option.value);
                                                       }}
                                                   >
                                                       {option.label}
                                                   </div>
                                               ))}
                                           </div>
                                       </div>
                                   </div>

                                   <div className='permissionBtns gR3 g'>
                                       <div className={`header bold cL g ${viewType === "full"  ? 'gC1 gR4' : 'gC1 gR6'} dG`}>
                                           Account Creation
                                       </div>
                                       <div className={`rowData preferences g ${viewType === "full" ? 'gC1 gR5' : 'gC1 gR7'} s`}>
                                           <div className="trackBar cC g fR">
                                               <div className="f cC">
                                                   <PersonAddAltTwoToneIcon/>
                                               </div>
                                               {uploadOptions.map((option) => (
                                                   <div
                                                       key={option.value}
                                                       className={`option bold f cC dP p${
                                                           tempCreatePermission === null ? selectedUser?.creationAllowed === option.value ? " active" : ""
                                                           :
                                                           tempCreatePermission === option.value ? " active" : ""
                                                       }`}
                                                       onClick={() => {
                                                           
                                                           creatingUser && 
                                                               setSelectedUser((prev) => ({
                                                                   ...prev,
                                                                   creationAllowed: option.value,
                                                               }));
                                                           selectedItemIndex !== null &&
                                                               setTempCreatePermission(option.value);
                                                       }}
                                                   >
                                                       {option.label}
                                                   </div>
                                               ))}
                                           </div>
                                       </div>
                                       {session?.user?.data?.views?.includes("Research Hub") && 
                                           <>
                                               <div className={`header bold cL g ${viewType === "full"  ? 'gC2 gR4' : 'gC1 gR8'} dG`}>
                                                       Research Hub
                                               </div>
                                               <div className={`rowData preferences g ${viewType === "full" ? 'gC2 gR5' : 'gC1 gR9'} s`}>
                                                   <div className="trackBar cC g fR">
                                                       <div className="f cC">
                                                           <TravelExploreIcon/>
                                                       </div>
                                                       {uploadOptions.map((option) => (
                                                           <div
                                                               key={option.value}
                                                               className={`option bold f cC dP p${
                                                                   tempResearchPermission === null ? selectedUser?.researchAllowed === option.value ? " active" : ""
                                                                   :
                                                                   tempResearchPermission === option.value ? " active" : ""
                                                               }`}
                                                               onClick={() => {    
                                                                   creatingUser && 
                                                                       setSelectedUser((prev) => ({
                                                                           ...prev,
                                                                           researchAllowed: option.value,
                                                                       }));
                                                                   selectedItemIndex !== null &&
                                                                       setTempResearchPermission(option.value);
                                                               }}                                                       
                                                           >
                                                               {option.label}
                                                           </div>
                                                       ))}
                                                   </div>
                                               </div>
                                           </>
                                       }
                                   </div>
                               </div>
                           </div>
                       </div>

                    }
                </div>
            </div>
    )
}

export default AccountCenter;
