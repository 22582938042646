import React from 'react';
import ReactDOM from 'react-dom/client';

import './CSS/index.css';
import './CSS/envTemplate.css';
import './CSS/AdminBoard.css';
import './CSS/Reporting.css';
import './CSS/ReportSheet.css';
import './CSS/Dashboard.css';
import './CSS/DonutChart.css';
import './CSS/LifeTracPortal.css';
import './CSS/PolicyTrackline.css';
import './CSS/NavBar.css';
import './CSS/IndividualProfile.css';
import './CSS/InputField.css';
import './CSS/NestedOverlays.css';
import './CSS/Overlays.css';
import './CSS/Login.css';
import './CSS/Unsubscribe.css';
import './CSS/MessageBoard.css';
import './CSS/dynamicMid.css';
import './CSS/dynamicMobile.css';
import './CSS/ReportSheet.css';
import './CSS/ResearchHub.css';
import './CSS/AccountCenter.css';


import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();