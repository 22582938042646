import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import WarningIcon from '@mui/icons-material/Warning';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Fix the default marker icon issue in Leaflet with Webpack 
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

function MapComponent(props) {
  const isValidPosition = (latitude, longitude) => latitude !== null && longitude !== null;
  
  const [position, setPosition] = useState(isValidPosition(props?.latitude, props?.longitude) 
    ? [props?.latitude, props?.longitude] 
    : null);

  const handleLocationChange = (event) => {
    event.preventDefault();
    const { latitude, longitude } = event.target.elements;
    setPosition([parseFloat(latitude.value), parseFloat(longitude.value)]);
  };

  if (!position) {
    return <div className='cC'><div className='cC dG'><WarningIcon/>Geographic coordinate data was not found.</div></div>; // Placeholder for invalid data
  }

  return (
    <div>
      <MapContainer center={position} zoom={5} style={{ height: '100%', width: '100%', zIndex: '0' }} attributionControl={false}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={position}>
          <Popup>
            A pretty CSS3 popup. <br /> Easily customizable.
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  );
};

export default MapComponent;