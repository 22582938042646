import React, { useEffect, useState, useRef} from 'react';
import { useNavigate, Link } from "react-router-dom";

import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone';
import SettingsIcon from '@mui/icons-material/Settings';
import UploadTwoToneIcon from '@mui/icons-material/UploadTwoTone';
import FolderSharedTwoToneIcon from '@mui/icons-material/FolderSharedTwoTone';
import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded';
import NotificationsActiveRoundedIcon from '@mui/icons-material/NotificationsActiveRounded';
import FindInPageTwoToneIcon from '@mui/icons-material/FindInPageTwoTone';
import TravelExploreTwoToneIcon from '@mui/icons-material/TravelExploreTwoTone';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import UploadIcon from '@mui/icons-material/Upload';

import Dropdown from '../Components/Dropdown.js';
import Tab from '../Components/Tab.js';
import Logo from '../Images/Logo.png';
import { FindInPageTwoTone } from '@mui/icons-material';

function NavBar(props){
    const session = props?.session;
    const initialize = props?.initialize;
    const loading = props?.loading;
    const individualData = session?.individual?.data;
    const userFunctions = session?.user?.functions;
    const currentPage = session?.user?.data?.currentActivity;
    const viewType = session?.env?.viewport?.viewType;
    const hasNotifications = !!Object.keys(session?.notifications?.data || {}).length;

    const navigate = useNavigate();
    const currentPath = window.location.pathname.replace(/\//g, '');

    function toggleOverlay(overlay){
        session?.env?.setOverlay(session?.env?.overlay === overlay ? undefined : overlay);
    }

    useEffect(() => {
        // if (viewType === "full" && currentPage === "details") {
        //     navigate("/dashboard");
        //     userFunctions?.updateUserData(["currentActivity"], ["dashboard"]);
        // }
    }, [viewType, currentPage]);

    useEffect(() => {
        if (!session?.user?.data?.currentActivity) {
            userFunctions?.updateUserData(["currentActivity"], [currentPath] || ["dashboard"]);
        }
    }, [session?.user?.data, currentPath]);
    
    useEffect(() => {
        const validPaths = ["dashboard", "reporting", "upload", "researchHub"];
        if(currentPath && validPaths.includes(currentPath) && session?.user?.data?.currentActivity !== currentPath){
           userFunctions?.updateUserData(["currentActivity"], [currentPath] || ["dashboard"]);
        }else if(currentPath === "unsubscribe"){
            toggleOverlay("emailPreferences");
        }
    }, [currentPath]);

    useEffect(()=>{
        const validPaths = ["dashboard", "reporting", "upload", "researchHub", "unsubscribe"];

        if(!currentPath || !validPaths.includes(currentPath)){
            navigate("/dashboard", { replace: true });
            userFunctions?.updateUserData(["currentActivity"], ["dashboard"]);
            window.history.replaceState(null, '', '/dashboard');
        }

        if(currentPath === "unsubscribe"){
            toggleOverlay("emailPreferences");
            if(viewType === "mobile"){
                navigate("/dashboard");
            }else{
                navigate("/dashboard");
            }
            window.history.replaceState(null, '', '/dashboard');
        }
    }, []);

    // Account Dropdown Handler

    const [accountNames, setAccountNames] = useState([]);

    const [currentName, setCurrentName] = useState();

    function findAccountIdByName(name, dictionary) {
        for (const accountId in dictionary) {
            if (dictionary[accountId].name === name) {
                return accountId; // Return the accountID if the name matches
            }
        }
        return null; // Return null if no match is found
    }

    const dropdownRef = useRef(null);

    // Calculate the width based on the longest name
    
    const calculateWidth = () => {
        const longestName = accountNames.reduce(
            (longest, name) => (name.length > longest.length ? name : longest),
            ''
        );
        const dropdownWidth = longestName.length * 10.5; // You can adjust this multiplier for better fit
        return dropdownWidth;
    };

    if (dropdownRef.current) {
        if(viewType === 'mobile'){
            dropdownRef.current.children[0].children[0].style.width = `220px`;
        }else{
            const dropdownWidth = calculateWidth();
            dropdownRef.current.children[0].children[0].style.width = `${dropdownWidth}px`;
            // dropdownRef.current.children[0].children[0].style.maxWidth = `${dropdownWidth}px`;
            dropdownRef.current.children[0].children[0].style.display = 'inline-block';
            // dropdownRef.current.children[0].children[0].style.whiteSpace = 'nowrap';
            // dropdownRef.current.children[0].children[0].style.textOverflow = 'ellipsis';
            // dropdownRef.current.children[0].children[0].style.overflowX = 'hidden';
        }
        
    }

    const tabSetup = () => {
      if(session?.user?.data?.views?.includes("Upload") && session?.user?.data?.views?.includes("Research Hub") && session?.user?.data?.views?.includes("Account Center") ){
        return 5;
        }
      else if((session?.user?.data?.views?.includes("Upload") && session?.user?.data?.views?.includes("Research Hub")) || (session?.user?.data?.views?.includes("Upload") && session?.user?.data?.views?.includes("Account Center")) ){
        return 4;
      }
      else if(session?.user?.data?.views?.includes("Upload") || session?.user?.data?.views?.includes("Research Hub")){
        return 3;
      }
      else{
        return 2;
      } 
    }

    useEffect(() => {
        if(session?.handler?.data?.currentName){
            let newAccountID = findAccountIdByName(session?.handler?.data?.currentName, session?.account?.data);
            session?.handler?.setData((prevData) => ({
                ...prevData,
                currentAccountID: newAccountID
            }));
            session?.user?.functions?.updateUserData(['accountID'], [newAccountID]);
            const currentAccountData = {
                id: newAccountID,
                name: session?.handler?.data?.currentName
            };
            const jsonString = JSON.stringify(currentAccountData);
            localStorage.setItem('currentAccount', jsonString);
    
        }
        
    }, [session?.handler?.data?.currentName]);

    useEffect(() => {

        if (session?.handler?.data?.currentAccountID && !session?.reporting?.data[session?.handler?.data?.currentAccountID]?.deceased) {
            initialize?.loadDeceased(session, session?.handler?.data?.currentAccountID);
            initialize?.loadReportingData(session, session?.handler?.data?.currentAccountID);
        } else {
            session?.individual?.setData(`${session?.handler?.data?.currentAccountID}.deathTrac.deceased`, session?.individual?.data[session?.handler?.data?.currentAccountID]?.deathTrac?.deceased);
            session?.individual?.setData(`${session?.handler?.data?.currentAccountID}.groups`, session?.individual?.data[session?.handler?.data?.currentAccountID]?.groups);
            session?.reporting?.setData(`${session?.handler?.data?.currentAccountID}`, 'deceased', session?.reporting?.data[session?.handler?.data?.currentAccountID]?.deceased);
            session?.reporting?.setData(`${session?.handler?.data?.currentAccountID}.deceased`, "termDeaths", session?.reporting?.data[session?.handler?.data?.currentAccountID]?.deceased?.termDeaths);
            session?.reporting?.setData(`${session?.handler?.data?.currentAccountID}.deceased.termDeaths`, "reportedDeaths", session?.reporting?.data[session?.handler?.data?.currentAccountID]?.deceased?.reportedDeaths);
            session?.reporting?.setData(`${session?.handler?.data?.currentAccountID}.deceased.termDeaths`, "sourceCount", session?.reporting?.data[session?.handler?.data?.currentAccountID]?.deceased?.sourceCount);
            initialize?.setLoadHandler(prevState => ({
                ...prevState,
                allDeceased: true
            }));
        }
        if (!session?.reporting?.data[session?.handler?.data?.currentAccountID]?.allReports) {
            initialize?.loadReportingData(session, session?.handler?.data?.currentAccountID)
        } else{
            session?.reporting?.setData(`${session?.handler?.data?.currentAccountID}`, 'allReports', session?.reporting?.data[session?.handler?.data?.currentAccountID]?.allReports)
            initialize?.setLoadHandler(prevState => ({
                ...prevState,
                reportingData: true
            }));
        }

        // Reset sftp files
        session?.upload?.setData(prevData => ({
            ...prevData,
            sftpFiles: [],
            sftpHistory: [],
            sftpRetrievable: []
        })); 
        // Request new sftp files
        if(session?.upload?.data?.fileTabSelected === 'sftp'){
            session?.upload?.functions?.getSftpFiles(session);
        }

        session?.dashboard?.functions?.resetSelectedTerm();

        // session?.user?.functions?.updateUserData(['accountID'], [newAccountID]);
    }, [session?.handler?.data?.currentAccountID]);

    useEffect(() => {
        // list of names
        accountNames.length = 0;
        Object.keys(session?.account?.data).forEach((key, index) => {
            if(session?.account?.data?.[key]?.type === "LifeTrac"){
                accountNames.push(session?.account?.data?.[key]?.name)
                setAccountNames(accountNames);
            }     
        });
    }, []);

    const [selectedAccount, setSelectedAccount] = useState({
        showMenu : false
    });

    const removeFile = () => {  
        session?.upload?.setData(prevData => ({
            ...prevData,
            removeFile: true
        }));  
        session?.edit?.functions?.updateImportedList("columns", []);
        session?.edit?.functions?.updateReport("sorting", null, []);
        session?.upload?.functions?.updateReport("errorCountData", session?.upload?.data?.initialErrorCount);
    }

    return (
        <div className={`appHeader g f fR cC s${viewType === "mobile" ? '' : " moduleBlock"}`} onClick={() => setSelectedAccount({ showMenu: false })}>
            <Link to="/dashboard" className="logo f cC p">
                <img className="f cC" src={Logo} alt="Abacus Life Logo"/>
            </Link>
            {accountNames.length === 1 &&
                <div className="f cC bold">
                    {session?.account?.data?.[session?.handler?.data?.currentAccountID]?.name}
                </div>
            }
            {accountNames.length > 1 && 
                <div className='f cC' ref={dropdownRef}> 
                    <Dropdown 
                        list={accountNames}
                        className='accountDropdown cC f'
                        setShowMenu={(input) => {
                            setSelectedAccount((prev) => ({ 
                                showMenu: input ?? !prev.showMenu, 
                            }));
                        }}
                        default={session?.account?.data?.[session?.handler?.data?.currentAccountID]?.name}
                        showMenu={selectedAccount?.showMenu}
                        valuePointer="account"
                        onClick={(value)=>{ 
                            session?.handler?.setData((prevData) => ({
                                ...prevData,
                                currentName: value
                            }));
                            if (value !== session?.handler?.data?.currentName){removeFile()};  
                            setSelectedAccount((prev) => ({ ...prev, showMenu: false }));
                        }}
                    />
                </div>
            }
                <div className={`${tabSetup() === 5 
                                    ? 'tabs5' 
                                    : tabSetup() === 4 
                                        ? 'tabs4' 
                                        : tabSetup() === 3 
                                            ? 'tabs3' 
                                            : 'tabs2'} g cC fR f nS fC${viewType !== "full" ? " alt" : ''}`
                                }>
                    <div className="f cC fR g">
                        {viewType !== "mobile" &&
                            <>
                                <Tab
                                    isActive={currentPage === "dashboard"}
                                    onClick={() => userFunctions?.updateUserData(["currentActivity"], ["dashboard"])}
                                    label="Dashboard"
                                    icon={<DashboardTwoToneIcon />}
                                    alertType={"Status"}
                                    viewType={viewType}
                                    session={session}
                                    loading={loading}
                                />
                                <Tab
                                    isActive={currentPage === "reporting"}
                                    onClick={() => props?.session?.user?.functions?.updateUserData(["currentActivity"], ["reporting"])}
                                    label="Reporting"
                                    icon={<AssessmentTwoToneIcon />}
                                    viewType={viewType}
                                    session={session}
                                    loading={loading}
                                />
                                { session?.user?.data?.views?.includes("Upload") ?
                                    <Tab
                                        isActive={currentPage === "upload"}
                                        onClick={() => props?.session?.user?.functions?.updateUserData(["currentActivity"], ["upload"])}
                                        label="Upload"
                                        icon={<UploadTwoToneIcon />}
                                        viewType={viewType}
                                        alertType={"Message"}
                                        session={session}
                                        loading={loading}
                                    />
                                    :
                                    // <div className=" alt g f cC fC">
                                    // </div>
                                    <></>
                                }
                                { session?.user?.data?.views?.includes("Research Hub") ?
                                    <Tab
                                        isActive={currentPage === "researchHub"}
                                        onClick={() => props?.session?.user?.functions?.updateUserData(["currentActivity"], ["researchHub"])}
                                        label="Research Hub"
                                        icon={<TravelExploreTwoToneIcon/>}
                                        viewType={viewType}
                                        session={session}
                                        loading={loading}
                                    />
                                    :
                                    // <div className=" alt g f cC fC">
                                    // </div>
                                    <></>
                                }
                                { session?.user?.data?.views?.includes("Account Center") ?
                                    <Tab
                                        isActive={currentPage === "accountCenter"}
                                        onClick={() => props?.session?.user?.functions?.updateUserData(["currentActivity"], ["accountCenter"])}
                                        label="Account Center"
                                        icon={<ManageAccountsOutlinedIcon/>}
                                        viewType={viewType}
                                        session={session}
                                        loading={loading}
                                    />
                                    :
                                    // <div className=" alt g f cC fC">
                                    // </div>
                                    <></>
                                }
                            </>                                                                                                                           
                        }
                        <div className={`tab alt g f cC p fC ${tabSetup() === 5 ? 'gC6' : tabSetup() === 4 ? 'gC5' : tabSetup() === 3 ? "gC4" : "gC3"}`} onClick={() => toggleOverlay("notifications")}>
                            {
                                hasNotifications ?
                                    <div className="notificationsBtn f cC">
                                        <NotificationsActiveRoundedIcon/>
                                        <div className="count cC">
                                            {Object?.keys(session?.notifications?.data).length}
                                        </div>
                                    </div>
                                :
                                    <NotificationsNoneRoundedIcon/>
                            }
                        </div>
                        <div className={`tab alt g f cC p fC ${tabSetup() === 5 ? 'gC7' : tabSetup() === 4 ? 'gC6' : tabSetup() === 3 ? "gC5" : "gC4"}`} onClick={() => toggleOverlay("settings")}>
                            <SettingsIcon/>
                        </div>
                    </div>
                </div>            

        </div>
    )
};

export default NavBar;